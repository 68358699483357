import React from 'react'
import NavbarComponent from './Navbar'
import { CiSearch } from "react-icons/ci";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModuleCustomization() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
 
  return (
    <div>
<NavbarComponent/>
<div>
<div className='flex row'>
<div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p>Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
       

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
        <div style={{width:"68%",border:"1px solid black",margin:"10px"}}>
           

        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Modules" value="1" />
            <Tab label="Tab Groups" value="2" />
            <Tab label="Web Tab" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <div>
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
           <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
            <div>
                <button onClick={handleOpen} style={{backgroundColor:"blue",color:"white"}}>Organize Modules</button> &nbsp;
                <button><Link to="newmodule"style={{textDecoration:"none"}}>New Modules +</Link></button>

                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Organize Modules
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <div>You can choose to show or hide modules, or change the order in which they are displayed in the tab.</div>
              <br />
              <div>
                <ul>
                  <li><input type="checkbox" /> Home</li>
                  <li><input type="checkbox" /> Leads</li>
                  <li><input type="checkbox" /> Contacts</li>
                  <li><input type="checkbox" /> Accounts</li>
                  <li><input type="checkbox" /> Deals</li>
                  <li><input type="checkbox" /> Tasks</li>
                  <li><input type="checkbox" /> Meetings</li>
                  <li><input type="checkbox" /> Calls</li>
                  <li><input type="checkbox" /> Reports</li>
                  <li><input type="checkbox" /> Analytics</li>
                  <li><input type="checkbox" /> Products</li>
                </ul>
                </div> 
                <br />
                <div style={{display:"flex",gap:"15px"}}>
                  <button style={{backgroundColor:"blue",color:"white"}}onClick={handleClose}>Save</button>
                  <button onClick={handleClose}>Cancel</button>
                </div>
                
                           </div>
          </Typography>

        </Box>
      </Modal>
            </div>

           </div>
           <div>
           <table className='table'>
            <thead>
                <tr>
                    <th>Displayed in tabs as</th>
                    <th>Module Name</th>
                    <th>Last Modified</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{color:"blue"}}> Leads</td>
                    <td>Leads</td>
                    <td></td>
                </tr>
                <tr>
                    <td style={{color:"blue"}}> Contacts</td>
                    <td>Contacts</td>
                    <td></td>
                </tr>
                <tr>
                    <td style={{color:"blue"}}> Accounts</td>
                    <td>Accounts</td>
                    <td></td>
                </tr>
                <tr>
                    <td style={{color:"blue"}}> Deals</td>
                    <td>Deals</td>
                    <td></td>
                </tr>
                <tr>
                    <td style={{color:"blue"}}> Tasks</td>
                    <td>Tasks</td>
                    <td></td>
                </tr>
            </tbody>
         </table>
           </div>
            </div>
        </TabPanel>
        <TabPanel value="2">
            <div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <h4>Tab Group</h4>
                    <button onClick={handleOpen}style={{backgroundColor:"blue",color:"white"}}>+ New Tab Group</button>                    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Tab Group
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <div>
            <label htmlFor="">Name :&nbsp;</label>
            <input type="text" /> <br /><br />

            <label htmlFor="">Tabs</label>
            <div style={{overflowY:"scroll"}}>
                <li><input type="checkbox" /> Home</li>
                <li><input type="checkbox" /> Leads</li>
                <li><input type="checkbox" /> Contacts</li>
                <li><input type="checkbox" /> Details</li>
                <li><input type="checkbox" /> Accounts</li>
                <li><input type="checkbox" /> Reports</li>
                <li><input type="checkbox" /> Analytics</li>
                <li><input type="checkbox" /> Products</li>
            </div>
            <br />

            <div style={{display:"flex",gap:"10px"}}>
                <button onClick={handleClose}>Cancel</button>
                <button onClick={handleClose} style={{backgroundColor:"blue",color:"white"}}>Save</button>
            </div>
           </div>
          </Typography>
        </Box>
      </Modal>
                </div>
                <div>
                <table className='table'>
            <thead>
                <tr>
                    <th>Group Name</th>
                    <th>Tabs</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td > ABC</td>
                    <td>Home , Leads</td>
                </tr>
             
               
            </tbody>
         </table>
                </div>
            </div>
        </TabPanel>
        <TabPanel value="3">
            <div>
                <div>
                    <h4>Web Tabs</h4>
                    <p>Web tabs let you open web pages, like intranet pages, company-wide announcements, or web applications, inside Zoho CRM. These tabs can be viewed by any Zoho CRM users in your organization. You can also create your own applications using Zoho Creator and access them inside Zoho CRM.</p>
                </div>
                <div>
                    <button style={{backgroundColor:"blue",color:"white"}} onClick={handleOpen}>+ New Tab</button>
                    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Create Web Tab
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <div>
            <label htmlFor="" className='col-md-4'>Tab Name -</label>&nbsp;
            <input type="text" className='col-md-6'/>

            <br /><br />
            <label htmlFor=""className='col-md-4' >Tab Name -</label>&nbsp;
            <input type="radio" /><label htmlFor="">Link</label> &nbsp;
            <input type="radio"/><label htmlFor="">Widges</label>
           <br /><br />
           <h5>Web Tab Access</h5>
           <select name="" id=""className='p-1'>
            <option value="">Administrator</option>
            <option value="">Standard</option>
           </select>
<br />
<br />
           <div style={{display:"flex",gap:"10px"}}>
            <button onClick={handleClose} style={{backgroundColor:"blue",color:'white'}}>Save</button>
            <button onClick={handleClose}>Cancel</button>
           </div>

           </div>
          </Typography>
        </Box>
      </Modal>
                </div>
            </div>
        </TabPanel>
      </TabContext>

        </div>
      </div>
</div>

    </div>
  )
}

export default ModuleCustomization
import React from 'react'
import NavbarComponent from './Navbar'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CiSearch } from "react-icons/ci";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from 'react-router-dom';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function PipelineCustomization() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div>
        <NavbarComponent/>
        <div>
        <div className='flex row'>
        <div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p>Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
      
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
        <div style={{width:"68%",border:"1px solid black",margin:"10px"}}>
            <div style={{display:"flex",justifyContent:"center"}}>
                <h4>Manage Pipelines</h4>
            </div>
      <div style={{display:"flex",gap:"20px"}}>
      <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="	https://static.zohocdn.com/crm/images/ill_pipeline1_6e9922b9a51b60c51f28e8d6340bd6f9_.svg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Streamline Your Sales Process
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Create multiple pipelines with different stages for your business needs.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://static.zohocdn.com/crm/images/ill_pipeline3_30ec9b92a1b07e0b1b3081f884732a73_.svg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Track Your Deals In Appropriate Pipeline
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Based on the type of deals, they would be processed in different pipelines.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://static.zohocdn.com/crm/images/ill_pipeline1_6e9922b9a51b60c51f28e8d6340bd6f9_.svg"
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Converge Deals Faster
          </Typography>
          <Typography variant="body2" color="text.secondary">
          Converge deals faster and smarter through various sales processes.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

      </div>
      <div style={{display:"flex",justifyContent:"center",padding:"10px"}}>
      <div>
      <button onClick={handleOpen} style={{backgroundColor:"blue",color:"white"}}>Create New Pipeline</button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Pipeline
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <div>
            <label htmlFor="" className='col-md-6'>Pipeline Name : &nbsp;</label>
            <input type="text"className='col-md-6' />
            <br />
            <br />
            <label htmlFor=""className='col-md-6'>Pipeline Name : &nbsp;</label>
            <select name="" id="" className='p-1 col-md-6'>
                <option value="">Standard</option>
            </select>
            <br />
            <br />
            <label htmlFor=""className='col-md-6'>Stages : &nbsp;</label>
            <select name="" id="" className='p-1 col-md-6'>
                <option value="">Qualification</option>
                <option value="">Needs Analysis</option>
                <option value="">Value Proportion</option>
            </select>
            <br />
            <br />
            <label htmlFor="">Set as default : &nbsp;</label>
            <input type="checkbox" />
            <br />
            <br />
            <div>
                <button onClick={handleClose}>Cancel</button>&nbsp;
                <button style={{backgroundColor:"blue",color:"white"}} onClick={handleClose}>Save</button>
            </div>
           </div>
          </Typography>
        </Box>
      </Modal>
    </div>
      </div>
        </div>
      </div>

  
        </div>
    </div>
  )
}

export default PipelineCustomization
import React from 'react'
import NavbarComponent from './Navbar'
import { CiSearch } from "react-icons/ci";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from 'react-router-dom';


function PersonalSettings() {
  return (
    <div>

      <NavbarComponent/>

      <div className='flex row'>
        <div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p>Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
      

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
        <div style={{width:"68%",border:"1px solid black",margin:"10px"}}>
           <div>
           <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Personal Settings</p>
           </div>

           <div>
            <div style={{display:"flex",gap:"20px",alignItems:"center"}}>
              <img src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" style={{width:"93px",height:'93px'}} alt="" />
              <div style={{display:"flex",flexDirection:"column"}}>
                <span style={{fontWeight:"bold"}}>Mitesh Waghmode - <span style={{color:"red"}}>Administrator</span></span>
                <span>CEO at ABC</span>
                <span><MdOutlineEmail /> mitesh@gmail.com</span>

              </div>
            </div>
            <div>
            <Accordion style={{width:"350px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         Show Details
        </AccordionSummary>
        <AccordionDetails>
         <h5>User Information</h5>
         <ul>
          <li>First Name - Mitesh Waghmode</li>
          <li>Email - mitesh@gmail.com</li>
          <li>Role - CEO</li>
          <li>Profile - Administrator</li>
          <li>Date Of Birth - </li>
          <li>Phone - </li>
          <li>Mobile - </li>
         </ul>

         <h5>Address Information</h5>
         <ul>
          <li>Street</li>
          <li>City</li>
          <li>State</li>
          <li>Country</li>
         </ul>
        </AccordionDetails>
      </Accordion>

     
            </div>
            <br />
            <div>
     <h5>Locale Information</h5>
     <ul>
      <li>Language - English</li>
      <li>Country Locale - United States</li>
      <li>Date Format - DD/MM/YYYY</li>
      <li>Time Format - 12 Hours</li>
      <li>Number Format - 123,456.798</li>
     </ul>
     </div>
     <br />
     <div>
      <h5>Groups</h5>
      <ul>
        <li>Member in - </li>
      </ul>
     </div>
     <br />
     <div>
      <h5>Themes</h5>
     <div style={{display:"flex",gap:"10px",padding:'5px'}}>
     <FaCircle style={{fontSize:"30px",color:"red"}}/>
      <FaCircle style={{fontSize:"30px",color:"blue"}}/>
      <FaCircle style={{fontSize:"30px",color:"black"}}/>
      <FaCircle style={{fontSize:"30px",color:"yellow"}}/>
      <FaCircle style={{fontSize:"30px",color:"green"}}/>
      <FaCircle style={{fontSize:"30px",color:"orange"}}/>
      <FaCircle style={{fontSize:"30px",color:"skyblue"}}/>
      <FaCircle style={{fontSize:"30px",color:"gray"}}/>
      <FaCircle style={{fontSize:"30px",color:"violet"}}/>
      <FaCircle style={{fontSize:"30px",color:"pink"}}/>
      <FaCircle style={{fontSize:"30px",color:"wheat "}}/>
      <FaCircle style={{fontSize:"30px",color:"brown"}}/>
     </div>
     </div>
           </div>

        </div>
      </div>
    </div>
  )
}

export default PersonalSettings
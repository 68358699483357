import React from "react";
import "./newProfile.css";
import {
  MdOutlineAccountTree,
  MdOutlineCall,
  MdOutlineAccessTime,
  MdEmail,
  MdChairAlt,
  MdNightlightRound,
} from "react-icons/md";
import useAuth from "./Context";
import { GoPlusCircle } from "react-icons/go";
import { MdOutlineMessage, MdCall, MdOutlineVideoCall } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { PiArrowsDownUp } from "react-icons/pi";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { FaUmbrellaBeach } from "react-icons/fa";
import { IoAirplaneSharp } from "react-icons/io5";
import { GoTrophy } from "react-icons/go";
import { VscFileSubmodule } from "react-icons/vsc";
import { GoStopwatch } from "react-icons/go";
import { PiBuildingsFill } from "react-icons/pi";
import { FaRegClipboard } from "react-icons/fa6";
import { FaPlane } from "react-icons/fa";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function ReporteesProfile() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [value, setValue] = React.useState(0);

  const[userTab,setUserTab]=useState("Profile")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{}}>
      <div
        className="main"
        style={{ display: "flex", gap: "10px", paddingLeft: "85%" }}
      >
        <MdOutlineMessage
          style={{ backgroundColor: "white", fontSize: "30px" }}
        />
        <MdCall style={{ backgroundColor: "white", fontSize: "30px" }} />
        <MdOutlineVideoCall
          style={{ backgroundColor: "white", fontSize: "30px" }}
        />
        <CiStar style={{ backgroundColor: "white", fontSize: "30px" }} />
      </div>
      <div style={{ width: "100%", height: "25%", backgroundColor: "#eeeeee" }}>
        <div
          style={{
            margin: "40px",
            backgroundColor: "#eeeeee",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <img
              style={{
                width: "150px",
                height: "140px",
                position: "relative",
                top: "-95px",
              }}
              src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM="
              alt=""
            />
            <h4 style={{ marginTop: "60px" }}>
              S2-Lilly Williams <small>&nbsp; Yet To Check</small> <br />
              Administrations
            </h4>
          </div>
          <div style={{ padding: "30px" }}>
            <p>
              Reporting To <br px /> Mitesh Waghmode
            </p>
          </div>
        </div>
        <hr />
        <div style={{ display: "flex", gap: "20px", paddingLeft: "25px" }}>
          {/* <p>Profile</p>
          
          <p>Department</p>
          <p>Peers</p>
          <p>Related Data</p> */}
          <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Profile" onClick={()=>setUserTab("Profile")}/>
        <Tab label="Department" onClick={()=>setUserTab("Department")}/>
        <Tab label="Peers" onClick={()=>setUserTab("Peers")}/>
        <Tab label="Related Data" onClick={()=>setUserTab("Related Data")}/>
      </Tabs>
        </div>
      </div>

      {
        userTab=="Profile"?<> <div>

    
        <div style={{ border: "1px solid black", margin: "20px" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <MdOutlineAccountTree
                style={{ fontSize: "50px", paddingRight: "10px" }}
              />
              <p>
                Department
                <br />
                Management
              </p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <MdNightlightRound
                style={{ fontSize: "50px", paddingRight: "10px" }}
              />
              <p>
                Shift
                <br />
                General
              </p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <MdOutlineAccessTime
                style={{ fontSize: "50px", paddingRight: "10px" }}
              />
              <p>
                Time Zone
                <br />
                (GMT+05:30)
              </p>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <MdChairAlt style={{ fontSize: "50px", paddingRight: "10px" }} />
              <p>
                Seating Location
                <br />
                FL_EXEC_1
              </p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <MdEmail style={{ fontSize: "50px", paddingRight: "10px" }} />
              <p>
                Email
                <br />
                abc@gmail.com
              </p>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", padding: "10px" }}
            >
              <MdOutlineCall style={{ fontSize: "50px", paddingRight: "10px" }} />
              <p>
                Work Phone Number
                <br />
                305-555-1212
              </p>
            </div>
          </div>
        </div>
  
        <div style={{ border: "1px solid black", margin: "20px" }}>
          <div style={{ display: "flex", gap: "50%", padding: "15px" }}>
            <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>About</p>
            <div style={{ marginTop: "35px" }}>
              <GoPlusCircle style={{ fontSize: "57px", paddingLeft: "20px" }} />
              <br />
              <Button variant="primary" onClick={handleShow1}>
                ADD ABOUT
              </Button>
              <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                  <Modal.Title>Add About</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <label htmlFor="">Description</label>
                  <br />
                  <textarea name="" id=""></textarea>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose1}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleClose1}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
  
        <div style={{ border: "1px solid black", margin: "20px" }}>
          <div style={{ padding: "15px" }}>
            <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>
              Work Experience
            </p>
            <div style={{display:"flex",justifyContent:"space-around"}}>
              <div>
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Department</li>
                  <li>Management</li>
                </ul>
                <hr />
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Location</li>
                  <li>-</li>
                </ul>
                <hr />
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Designation</li>
                  <li>Administration</li>
                </ul>
                <hr />
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Department</li>
                  <li>Management</li>
                </ul>
                <hr />
              </div>
              <div>
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Zoho Role</li>
                  <li>Team Member</li>
                </ul>
                <hr />
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Employment Type</li>
                  <li>Permanant</li>
                </ul>
                <hr />
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Employee Status</li>
                  <li>Active</li>
                </ul>
                <hr />
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Total Experience</li>
                  <li>
                  15 year(s) 4 month(s)</li>
                </ul>
                <hr />
              </div>
            </div>
          </div>
        </div>
  
        <div style={{ border: "1px solid black", margin: "20px" }}>
          <div style={{ padding: "15px" }}>
            <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>
            Hierarchy Information
            </p>
            <div >
              <div>
                <ul style={{ display: "flex", listStyle: "none", gap: "50px" }}>
                  <li>Reporting Manager</li>
                  <li>Mitesh Waghmode</li>
                </ul>
                <hr />
               
               
              </div>
            
            </div>
          </div>
        </div>
  
  
        <div style={{ border: "1px solid black", margin: "20px" }}>
          <div style={{ padding: "15px" }}>
            <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>
              Work Experience
            </p>
            <div>
              <table style={{ margin: "auto auto" }}>
                <thead>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      Company Name &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      Job Title &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      From Date &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      To Date &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      Job Description &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      Relavant &nbsp;
                      <PiArrowsDownUp />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      Infomax
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      Team Lead
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      04 Jan 2009
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      20 Jan 2011
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      -
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      Yes
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
  
        <div style={{ border: "1px solid black", margin: "20px" }}>
          <div style={{ padding: "15px" }}>
            <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>
              Education Details
            </p>
            <div>
              <table style={{ margin: "auto auto" }}>
                <thead>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      Institute Name &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      Degree/Diploma &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      Specialization &nbsp;
                      <PiArrowsDownUp />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#eeeeee",
                        padding: "15px",
                        paddingRight: "25px",
                      }}
                    >
                      {" "}
                      Date Of Completion &nbsp;
                      <PiArrowsDownUp />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      Crestwood College
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      Master
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      Cybersecurity
                    </td>
                    <td
                      style={{
                        padding: "15px",
                        paddingRight: "25px",
                        textAlign: "center",
                      }}
                    >
                      -
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div></>:(userTab=="Department"?<>
        <div>
<div style={{display:"flex",gap:'100px',margin:"20px",border:"1px solid black",padding:"15px",backgroundColor:"white"}}> 
            <select style={{padding:"20px",paddingRight:"100px",fontSize:"20px"}} name="" id="">
                <option value="">My Department</option>
                <option value="">Management</option>
            </select>
            <select style={{padding:"20px",paddingRight:"100px",fontSize:"20px"}} name="" id="">
                <option value="" >Unspecified Loacation</option>
            </select>
            <p style={{fontSize:"20px"}}>5 <br /> Members</p>
        </div>
        <div style={{display:"flex",gap:"10px"}}>
          <div>

       
          <div style={{padding:"20px",border:"1px solid black",margin:"20px",borderRadius:"10px",backgroundColor:"white"}}>
            <div style={{display:"flex",gap:"230px"}}>
              <p style={{fontWeight:"bold",fontSize:"17px"}}>CEO</p>
              <p style={{fontWeight:"bold",fontSize:"17px"}} >1</p>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",gap:"20px"}}>
              <img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
              <p style={{fontWeight:"bold"}}>1-Mitesh Waghmode</p>
              <p style={{color:"red"}}>Yet To Check-in</p>
            </div>
          </div>
          </div>
          <div style={{padding:"20px",border:"1px solid black",margin:"20px",borderRadius:"10px",backgroundColor:"white"}}>
            <div style={{display:"flex",gap:"100px"}}>
              <p style={{fontWeight:"bold",fontSize:"17px"}}>Administrations</p>
              <p style={{fontWeight:"bold"}}>4</p>
            </div>
            <div>
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                <p>230-103-239</p>
                </div>

              <div>
                <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                  <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                  <p>230-103-239</p>
                </div>

              <div>
              <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                  <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                  <p>230-103-239</p>
                </div>

              <div>
              <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
</div></>:(userTab=="Peers"?<>
<div>
    <div style={{display:"flex",justifyContent:"space-around",paddingTop:"20px",backgroundColor:"#e7e5e5"}}>
        <div style={{display:"flex",gap:"20px"}}>
            <img style={{width:"50px",height:"50px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
            <p style={{fontSize:"20px",marginTop:"12px",fontWeight:"bold"}}>
            S2 - Lilly Williams</p>
        </div>
        <div style={{display:"flex",gap:"20px"}}>
            <button>Members - 3</button>
            
        </div>
    </div>
    <div style={{display:"flex",backgroundColor:"#e7e5e5"}}>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px",
    backgroundColor:"white"}}>
                <div>
                <img style={{width:"132px",height:"98px",padding:"5px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                                   </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px",
    backgroundColor:"white"}}>
                <div>
                <img style={{width:"132px",height:"98px",padding:"5px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                                   </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px",
    backgroundColor:"white"}}>
                <div>
                <img style={{width:"132px",height:"98px",padding:"5px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                                   </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    </div>
</div></>:<>
<div style={{display:"flex",gap:"20px",flexWrap:"wrap",width:"1000px",margin:"0 auto",backgroundColor:"#e7e5e5",width:"100%",padding:"40px"}}>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
        
        <FaUmbrellaBeach  style={{fontSize:"25px"}}/>
            <p style={{marginTop:"10px"}}>Leave Tracker</p>
        
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
    <VscFileSubmodule  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Files</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
    <GoStopwatch  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>TIme Tracker</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
    <PiBuildingsFill  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Exit Details</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
    <FaRegClipboard  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Attendance</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
    <IoAirplaneSharp  style={{fontSize:"25px"}}/>
   <p style={{marginTop:"10px"}}>Travel Expense</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
    <GoTrophy  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Performance</p>
    </div>
   
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px",backgroundColor:"white"}}>
    <FaPlane  style={{fontSize:"25px"}}/>
        <p style={{marginTop:"10px"}}>Travel Request</p>
    </div>
</div></>))
      }



    </div>
  );
}

export default ReporteesProfile;

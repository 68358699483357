const actions = [
    { id: 1, name: "Mass Update" },
    { id: 2, name: "Mass Delete" },
    { id: 3, name: "Mass Email" },
    { id: 4, name: "Approve" },
    { id: 5, name: "Add To Campaigns" },
    { id: 6, name: "Export " },
    { id: 7, name: "Transfer" }
  ];
 
  export default actions;
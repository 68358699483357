import React,{useState} from 'react'
import NavbarComponent from './Navbar'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import {Chip, } from '@mui/material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function Newmodule() {
    const [inputValue, setInputValue] = useState('');
    const [words, setWords] = useState([]);
  
    // Function to handle input change
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
  
    // Function to handle key press events
    const handleKeyPress = (event) => {
      if (event.key === 'Enter' && inputValue.trim()) {
        setWords([...words, inputValue.trim()]); // Add new word
        setInputValue(''); // Clear input field
      }
    };
  
    // Function to handle word removal
    const handleDeleteWord = (wordToRemove) => {
      setWords(words.filter((word) => word !== wordToRemove));
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
        
        <NavbarComponent/>
        <div style={{display:"flex",justifyContent:"space-around",padding:"10px"}}>
            <div><h4>Standard</h4></div>
            <div style={{display:"flex",gap:"10px"}}>
                <button style={{backgroundColor:"blue",color:"white"}} onClick={handleOpen}>Create Module +</button>
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Module Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
<div>
    <div>
        <p>Module Name</p>
    </div>
    <p>Plural form of module name</p>
    <div>
        <input type="text" /><label htmlFor="">(i.e. Leads/Contacts)</label>
    </div>
    <p>Singular form of module name</p>
    <div>
        <input type="text" /><label htmlFor="">(i.e. Leads/Contacts)</label>
    </div>
   
    <div>
    <Box sx={{ maxWidth: 400, margin: '0 auto', padding: '20px' }}>
     
      <h5>Module Permission</h5>
      <p>Select the profile(s) that should have access to the module.</p>
      <TextField
        label="Add or Select role"
        fullWidth
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <Box sx={{ marginTop: '20px' }}>
        {words.map((word, index) => (
          <Chip
            key={index}
            label={word}
            onDelete={() => handleDeleteWord(word)}
            sx={{ margin: '5px' }}
          />
        ))}
      </Box>
    </Box>
    </div>
    <div style={{display:"flex",gap:"10px"}}>
        <button style={{backgroundColor:"blue",color:"white"}} onClick={handleClose}>Save</button>
        <button onClick={handleClose}>  Cancel</button>
    </div>
    </div>          </Typography>
        </Box>
      </Modal>
            </div>
        </div>
        <div>
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="CREATE" value="1" />
            <Tab label="QUICK CREATE" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <div>
            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Untitled Name"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Single Line</label>
       
      </div>
      <br />
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Untitled Owner"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Lookup</label>
       
      </div>
      <br />
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Email</label>
       
      </div>
      <br />
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Secondary Email"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Email</label>
       
      </div>
      <br />
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Created By"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Single Line</label>
       
      </div>
      <br />
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Modified By"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Single Line</label>
       
      </div>
     
      
    </Box>
            </div>
        </TabPanel>
        <TabPanel value="2">
            <div>
            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Untitled Name"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Single Line</label>
       
      </div>
      <br />
      <div style={{border:"1px solid black",display:"flex",alignItems:"center",width:"40%"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Email"
          multiline
          maxRows={4}
        />
        <label htmlFor="">Email</label>
       
      </div>
      <br />
     
    
     
     
     
      
    </Box>
            </div>
        </TabPanel>
      </TabContext>
        </div>
    </div>
  )
}

export default Newmodule
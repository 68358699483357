import React from 'react'
import { Link } from 'react-router-dom';

function Reportees() {
    
    const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    borderBottom: '1px solid #ddd',
    
    backgroundColor:"white"
};

const leftStyle = {
    display: 'flex',
    alignItems: 'center',
};

const profileStyle = {
    display: 'flex',
    alignItems: 'center',
};

const profileImageStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    marginRight: '10px',
};

const profileDetailsStyle = {
    display: 'flex',
    flexDirection: 'column',
};

const breadcrumbStyle = {
    marginLeft: '20px',
};

const rightStyle = {
    display: 'flex',
    alignItems: 'center',
};

const menuStyle = {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: 0,
};

const menuItemStyle = {
    marginRight: '20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
};

const activeMenuItemStyle = {
    fontWeight: 'bold',
};

const tagStyle = {
    marginLeft: '5px',
    backgroundColor: '#f1f1f1',
    borderRadius: '12px',
    padding: '2px 8px',
};



return (
    <div style={{backgroundColor:"#E5E5F0",height:"100vh"}}>

    <div style={containerStyle}>
        <div style={leftStyle}>
            <div style={breadcrumbStyle}>
                <div className="subFilterIcn" userid="162821000000254001">
                    <span className="zpl_em" style={profileStyle}>
                        <div className="zpl_emimg">
                            <Link to="/team/reportees/reporteesProfile">
                           
                            <img
                                style={profileImageStyle}
                                src="https://contacts.zoho.in/file?ID=60029573577&amp;fs=thumb"
                                alt="profile"
                                />
                                 </Link>
                        </div>
                        <div className="zpl_emdts zpl_ellipsis" style={profileDetailsStyle}>
                            <div>
                                1 - <b>Mitesh Waghmode</b>
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <div id="home-team-btn_wrap" style={rightStyle}>
            <ul style={menuStyle} id="home-team-reporteeType">
                <li style={{ ...menuItemStyle, ...activeMenuItemStyle }} reptype="0">
                    <span>Direct</span>
                    <div className="zpl_tg" style={tagStyle}>
                        <span id="dCnt">4</span>
                    </div>
                </li>
                <li style={menuItemStyle} reptype="1">
                    <span>All</span>
                    <div className="zpl_tg" style={tagStyle}>
                        <span id="aCnt">19</span>
                    </div>
                </li>
            </ul>
            
        </div>
       
    </div>
    <div style={{display:"flex",flexWrap:"wrap"}}>

  
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px",
    backgroundColor:"white"}}>
                <div>
                <Link to="/team/reportees/reporteesProfile"> <img style={{width:"119px",height:"92px",padding:"5px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                </Link>
                </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px",
    backgroundColor:"white"}}>
                <div>
                <Link to="/team/reportees/reporteesProfile"> <img style={{width:"119px",height:"92px",padding:"5px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                </Link>                     </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px",
    backgroundColor:"white"}}>
                <div>
                <Link to="/team/reportees/reporteesProfile"> <img style={{width:"119px",height:"92px",padding:"5px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                </Link>                    </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px",
    backgroundColor:"white"}}>
                <div>
                <Link to="/team/reportees/reporteesProfile"> <img style={{width:"119px",height:"92px",padding:"5px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                </Link>                  </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    </div>
    </div>

  )
}

export default Reportees
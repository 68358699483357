import React from "react";
import NavbarComponent from "./Navbar";
import { CiSearch } from "react-icons/ci";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const label = { inputProps: { "aria-label": "Switch demo" } };

function ScheduleAuto() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
      <NavbarComponent />

      <div className="flex row">
        <div
          style={{ width: "22%", border: "1px solid black", margin: "10px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "bold", padding: "10px" }}
            >
              Setup
            </p>
            <div>
              <CiSearch
                style={{
                  border: "2px solid black",
                  fontSize: "27px",
                  marginBottom: "5px",
                  borderRight: "0px ",
                  borderRadius: "5px 0px 0px 5px",
                  backgroundColor: "white",
                }}
              />
              <input
                type="search"
                style={{
                  outline: "none",
                  borderLeft: "0px solid black",
                  padding: "0px",
                  borderRadius: "0px 5px 5px 0px",
                }}
                placeholder="Search"
              />
            </div>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ fontWeight: "bold" }}
              >
                General
              </AccordionSummary>
              <AccordionDetails>
                <Link
                  to="/setting/personalsetting"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <p>Personal Settings</p>
                </Link>
                <Link
                  to="/setting/usersetting"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <p>Users</p>
                </Link>
                <Link
                  to="/setting/companysetting"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  <p>Company Settings</p>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontWeight: "bold" }}
              >
                Channels
              </AccordionSummary>
              <AccordionDetails>
                <Link to="/setting/email" style={{ textDecoration: "none" }}>
                  <p>Email</p>
                </Link>
               
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                style={{ fontWeight: "bold" }}
              >
                Automation
              </AccordionSummary>
              <AccordionDetails>
                <Link to="/setting/workflow" style={{ textDecoration: "none" }}>
                  <p>Workflow Rules</p>
                </Link>
                <Link to="/setting/action" style={{ textDecoration: "none" }}>
                  <p>Actions</p>
                </Link>
                <Link to="/setting/schedule" style={{ textDecoration: "none" }}>
                  <p>Schedules</p>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                style={{ fontWeight: "bold" }}
              >
                Customization
              </AccordionSummary>
              <AccordionDetails>
                <Link to="/setting/modules" style={{ textDecoration: "none" }}>
                  <p>Modules and fields</p>
                </Link>
                <Link
                  to="/setting/pipelines"
                  style={{ textDecoration: "none" }}
                >
                  <p>Pipelines</p>
                </Link>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div
          style={{ width: "68%", border: "1px solid black", margin: "10px" }}
        >
          <h4>Schedules</h4>
          <p>
            Schedules are customizable actions that can be triggered
            automatically using functions. Schedules can be used to integrate
            your Zoho CRM data with Zoho apps, your company's website, and other
            third-party applications.
          </p>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button onClick={handleOpen}style={{backgroundColor:"blue",color:"white"}}>Create New Schduled</button>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Create New Schedule
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
         <div>
          <label htmlFor="" className="col-md-6">Schedule Name : </label>
          <input type="text" className="col-md-6"/>
          <br />
          <br />
          <label htmlFor=""className="col-md-6">Function To Be Executed</label>
          <select name="" id=""className="col-md-6 p-1" >
            <option value="">--select--</option>
            <option value="">From existing function</option>
            <option value="">Writing Function</option>
          </select>
          <br /><br />
          <button onClick={handleClose} style={{backgroundColor:"blue",color:"white"}}>Save</button>&nbsp;
          <button onClick={handleClose}>Cancel</button>
         </div>
          </Typography>
        </Box>
      </Modal>
          </div>
          <div>
            <table className="table">
              <thead>
                <tr>
                  <th> Schedule Name</th>
                  <th>Last Run</th>
                  <th>Next Run</th>
                  <th>Frequency</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ABC</td>
                  <td>- </td>

                  <td>In 25 Minutes</td>

                  <td>Once</td>
                  <td>
                    {" "}
                    <Switch {...label} defaultChecked />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScheduleAuto;

import React, { useState } from 'react'
import "./teamspace.css"
import pic1 from "../Images/boy.png";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function TeamSpace() {
    const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    const[tab,setTab]=useState("Department")
  return (
    <div>
        <div className='team-space'></div>

        <div style={{display:"flex",justifyContent:"space-around",backgroundColor:"#e4e2e2"}}>
            <div>
                <div style={{backgroundColor:"white",textAlign:"center",position:"relative",left:"-28px",top:"-38px",paddingLeft:"90px",paddingRight:"90px",borderRadius:"10px"}}>
                    <div style={{width:"100px",height:"100px",border:"1px solid black",position:"relative",left:"28px",top:"-40px",backgroundColor:"white",color:"black",textAlign:"center"}}> <p style={{marginTop:"25px",fontSize:"25px"}}>M</p> </div>
                    <p style={{paddingBottom:"10px",fontWeight:"bold"}}>Management</p>
                    <p style={{paddingBottom:"10px"}}>Team strength-5</p>
                </div>
                <div style={{backgroundColor:"white",paddingLeft:"90px",paddingRight:"90px",position:"relative",    left: "-29px",
    top: "-29px",
    borderRadius: "10px"}}>
                    <p style={{paddingTop:"10px",fontWeight:"bold"}}>Team Availability</p>
                    <p style={{paddingTop:"10px",paddingBottom:"10px"}}>Yet to check-in - 5</p>
                </div>
                <div style={{backgroundColor:"white",paddingLeft:"90px",paddingRight:"90px",position:"relative",    left: "-29px",
    top: "-29px",
    borderRadius: "10px"}}>
                    <p style={{paddingTop:"10px",fontWeight:"bold"}}>Location Diversity</p>
                    <p style={{paddingTop:"10px",paddingBottom:"10px"}}>Unspecified location - 5</p>
                </div>
            </div>
            <div>
                <div style={{display:"flex",gap:"25px",backgroundColor:"white",paddingRight:"200px",paddingLeft:"200px",cursor:"pointer",position:"relative",top:"-38px",borderRadius:"10px"}}>
                  {/* <p style={{padding:"20px",fontWeight:"bold "}} onClick={()=>setTab("Department")}>Department Wall</p>
                  <p style={{padding:"20px",fontWeight:"bold "}} onClick={()=>setTab("Group")}>Groups</p> */}
                  <Tab label="Department"  onClick={()=>setTab("Department")}/>
                  <Tab label="Group" onClick={()=>setTab("Group")}/>
                </div>
                {
                    tab=="Department"?<>
                    <div>
                        <div style={{backgroundColor:"white",marginTop:"10px",padding:"10px",display:"flex",textAlign:"center",justifyContent:"flex-start",gap:'20px',textAlign:'center',borderRadius:"10px"}}>
                        <img
        src={pic1}
        alt="Img"
        style={{
          border: "1px solid black",
          height: "40px",
          width: "40px",
        }}
      />

                            <p>Post Message To Your Department</p>
                        </div>
                        <div></div>
                    </div>
                    </>:<>
                    <div>
                    <div style={{backgroundColor:"white",marginTop:"10px",padding:"30px",display:"flex",textAlign:"center",justifyContent:"center",borderRadius:"10px"}}>
                            <img src="" alt="" />
                            <p>No Groups Created Yet</p>
                        </div>
                    </div>
                    </>
                }
                
            </div>
        </div>

        
    </div>
  )
}

export default TeamSpace
import React,{useEffect, useState} from 'react'
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function ProjectDashboard() {
  const[tab,setTab]=useState("Dashboards")
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [open, setOpen] = React.useState(false);
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <div>
  <div>

    <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div  style={{ display: 'flex', flexDirection: 'column' }}>
        <span  style={{ display: 'flex', alignItems: 'center',padding:"20px" }}>
          <b   style={{ maxWidth: '250px', }}>CBS</b>
          <span  style={{ margin: '0 8px' }}>•</span>
          <em  style={{ color: 'orange' }}>In-Progress</em>
          <div  style={{ marginLeft: '8px' }}>
          </div>
        </span>
        <div  style={{ display: 'flex', alignItems: 'center',padding:"15px" }}>
          <div style={{ marginRight: '8px' }}>
            <img src="https://contacts.zoho.in/file?ID=60029573577&fs=thumb" alt="Profile" style={{ borderRadius: '50%',width:"40px",height:"40px" }} />
          </div>
          <div  style={{ maxWidth: '200px', }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                 <b title="Shivam Dilip Vitkare ">1 - Mitesh Waghmode </b>
                <span  style={{ margin: '0 8px' }}>•</span>
                <span style={{color:"black"}}>Project Head</span>
              </div>
              <div  style={{ marginLeft: '8px' }}>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div  style={{ marginLeft: 'auto' ,padding:"20px"}}>
        <div  style={{ whiteSpace: 'nowrap' }}>
          <ul style={{ listStyleType: 'none', padding: 0, margin: 0, display: 'flex', alignItems: 'center' }}>
            <li  style={{ marginRight: '16px' }}>
              <span style={{ maxWidth: '200px', }}>Client</span>
              <b  style={{ color: 'orange',  }}>-Mitesh Waghmode</b>
            </li>
            <li id="projectCostDiv" style={{ marginRight: '16px' }}>
              Project Cost <b  style={{ color: 'orange' }}>0</b>
            </li>
            <li id="projectUsersCountDiv">
              Project Users <b   style={{ color: 'orange' }}>6</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div style={{display:"flex",gap:"20px",paddingLeft:"30px"}}>
    
    {/* <p style={{fontWeight:"bold",cursor:"pointer"}} onClick={()=>setTab("Dashboards")}>Dashboards</p>
    <p style={{fontWeight:"bold",cursor:"pointer"}} onClick={()=>setTab("Feeds")}>Feeds</p>
    <p style={{fontWeight:"bold",cursor:"pointer"}} onClick={()=>setTab("Jobs")}>Jobs</p>
    <p style={{fontWeight:"bold",cursor:"pointer"}} onClick={()=>setTab("Users")}>Users</p>
    <p style={{fontWeight:"bold",cursor:"pointer"}} onClick={()=>setTab("Attachments")}> Attachments</p> */}

    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab style={{fontWeight:"bold",cursor:"pointer"}} label="Dashboards" onClick={()=>setTab("Dashboards")}/>
        <Tab style={{fontWeight:"bold",cursor:"pointer"}} label="Feeds" onClick={()=>setTab("Feeds")}/>
        <Tab style={{fontWeight:"bold",cursor:"pointer"}} label="Jobs" onClick={()=>setTab("Jobs")}/>
        <Tab style={{fontWeight:"bold",cursor:"pointer"}} label="Users"   onClick={()=>setTab("Users")}/>
        <Tab style={{fontWeight:"bold",cursor:"pointer"}} label="Attachments" onClick={()=>setTab("Attachments")} />
      </Tabs>
    </Box>
  </div>


  {/* Dashboard */}

{
  tab=="Dashboards"?<> <div style={{display:'flex',gap:"20px",flexWrap:"wrap",marginLeft:"40px",marginTop:"40px"}}>
  <div style={{width:"400px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px"}}>

<p style={{fontWeight:"bold"}}>Estimated vs logged hours</p>
<hr />
<p>Users are not tagged to any Jobs and there are no time logs</p>
</div>
    <div style={{width:"400px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px"}}>
<p style={{fontWeight:"bold"}}>In-Progress vs completed Jobs</p>
<hr />
<p>No Jobs added</p>
    </div>
    <div style={{width:"400px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px"}}>
<p style={{fontWeight:"bold"}}>Jobs - estimated vs logged hours</p>
<hr />
<p>No Jobs added</p>
</div>
<div style={{width:"800px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px",overflowY:"scroll"}}>
<p style={{fontWeight:"bold"}}>User - Jobs status</p>

<table className='table'style={{}}>
          <thead >
            <tr >
              <th style={{backgroundColor:"#e7e5e5"}}>Users</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Overdue</th>
              <th style={{backgroundColor:"#e7e5e5"}}>In-Progress</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Completed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
             
              
              <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              
            
            </tr>

            <tr>
             
              
             <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
             <td>0</td>
             <td>0</td>
             <td>0</td>
             
           
           </tr>

           <tr>
             
              
             <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
             <td>0</td>
             <td>0</td>
             <td>0</td>
             
           
           </tr>

           <tr>
             
              
             <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
             <td>0</td>
             <td>0</td>
             <td>0</td>
             
           
           </tr>
          </tbody>

        </table>
        


</div>
<div style={{width:"400px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px",overflowY:"scroll"}}>
<p style={{fontWeight:"bold"}}>Top 5 contributors</p>

<table className='table'style={{}}>
          <thead >
            <tr >
              <th style={{backgroundColor:"#e7e5e5"}}>Users</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Logged Hours</th>
            
            </tr>
          </thead>
          <tbody>
            <tr>
             
              
              <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
              <td>00:00 Hrs</td>
            
              
            
            </tr>

            <tr>
             
              
             <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
             <td>00:00 Hrs</td>
            
             
           
           </tr>

           <tr>
             
              
             <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
             <td>00:00 Hrs</td>
            
             
           
           </tr>

           <tr>
             
              
             <td> <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /> S2 - Lilly Williams  </td>
             <td>00:00 Hrs</td>
             
             
           
           </tr>
          </tbody>

        </table>

</div>
<div style={{width:"400px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px"}}>
<p style={{fontWeight:"bold"}}>Overdue Jobs</p>
<hr />
<p>No Jobs are overdue</p>
</div>
<div style={{width:"400px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px"}}>
<p style={{fontWeight:"bold"}}>Top 5 active Jobs</p>
<hr />
<p>There are no Jobs with time logs</p>
</div>

<div style={{width:"450px",height:"290px",backgroundColor:"#e7e5e5",padding:"10px"}}>
<p style={{fontWeight:"bold"}}>Weekly report</p>
<hr />
<p>No time logs added for this week</p>
</div>
  </div></>:(tab=="Feeds"?<> <div>
    <div style={{width:"1200px",height:'100px',border:"1px solid black",margin:"0 auto",display:"flex",alignItems:"center",gap:"25px",borderRadius:"10px",marginTop:"20px"}}>
          <img  style={{width:"50px",height:"50px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
          <p>Type @ Mention Someone</p>
    </div>
    <div style={{width:"80%",height:"300px",backgroundColor:"#e7e5e5",margin:"0 auto",alignItems:"center",display:"flex",justifyContent:"center"}}>
        <div style={{display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
          <img style={{width:"150px",height:"150px"}} src="https://static.zohocdn.com/zp5/people5/images/nodata/no_data_found.5d034c55f50a90857eac7e424b646d1a.svg" alt="" />
          <p style={{paddingTop:"10%",fontSize:"20px"}}> No Feeds Yet</p>
        </div>
      </div>
  </div></>:(tab=="Jobs"?<><div>
    <div style={{display:"flex",justifyContent:"flex-end",marginRight:"50px"}}>
    <Button variant="outlined" color="neutral" style={{backgroundColor:"blue",color:"white"}} onClick={() => setOpen(true)}>
        Add Job
      </Button>

      <Modal
    
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          variant="outlined"
          sx={{
            Width: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
           <div>
           Job Configuration Details
           </div>
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
           <div style={{width:"700px"}}>
             <div>
              <label className='col-4' htmlFor="">Job Name</label>
              <input className='col-6' type="text" />
             </div>
             <br/>
             <div>
              <label className='col-4' htmlFor="">Project</label>
              <input className='col-6' type="text" placeholder='CBS' disabled />
             </div>
             <br/>
             <div>
              <label className='col-4' htmlFor="">Start Date</label>
              <input className='col-6' type="date"  />
             </div>
             <br/>
             <div>
              <label className='col-4' htmlFor="">End Date</label>
              <input className='col-6' type="date"  />
             </div>
             <br/>
             <div>
              <label className='col-4' htmlFor="">Hours</label>
              <input className='col-6' type="text" placeholder='00:00'  disabled/>
             </div>
             <br/>
  
             <div>
              <label className='col-4' htmlFor="">Project Cost</label>
              <input className='col-6' type="text" />
             </div>
             <br/>
            
            
           <div>
             <label className='col-4' htmlFor="">Rate Per Hour</label>
              <input className='col-6' type="text"placeholder='Rate' />
             </div>
             <br/>
  
             <div>
              <label className='col-4' htmlFor="">Attachements</label>
              <input className='col-6' type="file" />
             </div>
             <br/>
  
             <div>
              <label className='col-4' htmlFor="">Description</label>
              <input className='col-6 p-2' type="text" />
             </div>
            <br/>
            <div>
             <label className='col-4' htmlFor="">Reminder</label>
              <input className='col-6' type="checkbox" />
             </div>
             <br/>
  
             <div style={{display:"flex",gap:"20px"}}>
              <button onClick={()=>setOpen(false)} style={{backgroundColor:"skyblue",color:"white",border:"1px solid blue"}}>Submit</button>
              <button onClick={()=>setOpen(false)} style={{backgroundColor:"red",color:"white",border:"1px solid red"}}>Cancel</button>
             </div>
           </div>
          </Typography>
        </Sheet>
      </Modal>
    </div>
      <br />
      <div style={{padding:"20px"}}>

        <table className='table'>

          <thead>
            <tr>
              <th style={{backgroundColor:"#e7e5e5"}}>Job Name</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Start Date</th>
              <th style={{backgroundColor:"#e7e5e5"} }>End Date</th>

              <th style={{backgroundColor:"#e7e5e5"}}>Estimated Hours</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Logged Hours</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Status</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Users</th>
            </tr>
          </thead>
          <tbody>
            <tr>
             
              
              <td> Data Analyst - CBS </td>

              <td>20 June 2024</td>
              <td>-</td>
              <td>0 Hrs 0 Mins</td>
              <td> 0 Hrs 0 Mins</td>
              <td>In-Progress</td>
              <td>1</td>
            
            </tr>
          </tbody>

        </table>
        
        </div>  
  </div></>:(tab=="Users"?<> <div style={{margin:"20px",display:"flex",gap:'20px',flexWrap:"wrap"}}>
    <div style={{width:"450px",height:"150px",border:"1px solid black",borderRadius:'10px'}}>
      <div style={{display:"flex",alignItems:"center",gap:'10px',padding:"10px"}}>
        <img style={{width:"45px",height:"45px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
        <p style={{marginTop:"5px",fontWeight:"bold"}}>
        1 - Mitesh Waghmode</p>
      </div>
      <hr />
      <div style={{display:"flex",padding:"10px",gap:"50px"}}>
        <p style={{fontWeight:"bold"}}>Role - Project Head</p>
        <p>Rate Per Hour - 0</p>
      </div>


    </div>
    <div style={{width:"450px",height:"150px",border:"1px solid black",borderRadius:'10px'}}>
      <div style={{display:"flex",alignItems:"center",gap:'10px',padding:"10px"}}>
        <img style={{width:"45px",height:"45px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
        <p style={{marginTop:"5px",fontWeight:"bold"}}>
        
S2 - Lilly Williams</p>
      </div>
      <hr />
      <div style={{display:"flex",padding:"10px",gap:"50px"}}>
        <p style={{fontWeight:"bold"}}>Role - <select style={{padding:"4px"}} name="" id=""><option value="">User</option><option value="">Manager</option></select></p>
        <p>Rate Per Hour - 0</p>
      </div>


    </div>
    <div style={{width:"450px",height:"150px",border:"1px solid black",borderRadius:'10px'}}>
      <div style={{display:"flex",alignItems:"center",gap:'10px',padding:"10px"}}>
        <img style={{width:"45px",height:"45px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
        <p style={{marginTop:"5px",fontWeight:"bold"}}>
        S6 - Ember Johnson</p>
      </div>
      <hr />
      <div style={{display:"flex",padding:"10px",gap:"50px"}}>
        <p style={{fontWeight:"bold"}}>Role - <select style={{padding:"4px"}} name="" id=""><option value="">User</option><option value="">Manager</option></select></p>
        <p>Rate Per Hour - 0</p>
      </div>


    </div>
    <div style={{width:"450px",height:"150px",border:"1px solid black",borderRadius:'10px'}}>
      <div style={{display:"flex",alignItems:"center",gap:'10px',padding:"10px"}}>
        <img style={{width:"45px",height:"45px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
        <p style={{marginTop:"5px",fontWeight:"bold"}}>
        S7 - Hazel Carter</p>
      </div>
      <hr />
      <div style={{display:"flex",padding:"10px",gap:"50px"}}>
        <p style={{fontWeight:"bold"}}>Role - <select style={{padding:"4px"}} name="" id=""><option value="">User</option><option value="">Manager</option></select></p>
        <p>Rate Per Hour - 0</p>
      </div>


    </div>
    <div style={{width:"450px",height:"150px",border:"1px solid black",borderRadius:'10px'}}>
      <div style={{display:"flex",alignItems:"center",gap:'10px',padding:"10px"}}>
        <img style={{width:"45px",height:"45px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
        <p style={{marginTop:"5px",fontWeight:"bold"}}>
        S9 - Caspian Jones</p>
      </div>
      <hr />
      <div style={{display:"flex",padding:"10px",gap:"50px"}}>
        <p style={{fontWeight:"bold"}}>Role - <select style={{padding:"4px"}} name="" id=""><option value="">User</option><option value="">Manager</option></select></p>
        <p>Rate Per Hour - 0</p>
      </div>


    </div>
    <div style={{width:"450px",height:"150px",border:"1px solid black",borderRadius:'10px'}}>
      <div style={{display:"flex",alignItems:"center",gap:'10px',padding:"10px"}}>
        <img style={{width:"45px",height:"45px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
        <p style={{marginTop:"5px",fontWeight:"bold"}}>
       
S10 - Lindon Smith</p>
      </div>
      <hr />
      <div style={{display:"flex",padding:"10px",gap:"50px"}}>
        <p style={{fontWeight:"bold"}}>Role - <select style={{padding:"4px"}} name="" id=""><option value="">User</option><option value="">Manager</option></select></p>
        <p>Rate Per Hour - 0</p>
      </div>


    </div>
  </div></>:<><div>
  <div style={{display:"flex",justifyContent:"flex-end",padding:"20px"}}>

  <Button
  style={{backgroundColor:"blue",color:"white",}}
  component="label"
  role={undefined}
  variant="contained"
  tabIndex={-1}
  startIcon={<CloudUploadIcon />}
  >
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
      </div>
  <div style={{width:"80%",height:"300px",backgroundColor:"#e7e5e5",margin:"0 auto",alignItems:"center",display:"flex",justifyContent:"center"}}>
        <div style={{display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
          <img style={{width:"150px",height:"150px"}} src="https://static.zohocdn.com/zp5/people5/images/nodata/no_data_found.5d034c55f50a90857eac7e424b646d1a.svg" alt="" />
          <p style={{paddingTop:"10%",fontSize:"20px"}}> No attachments added. Click here to upload</p>
        </div>
      </div>

  </div></>)))
}

 

  {/* Feeds */}

 

  {/* Jobs */}

  

  {/* Users */}

 

  {/* Attachments */}

  
  </div>
  )
}

export default ProjectDashboard
import React from 'react';
import NavbarComponent from './Navbar';
import { Container, Row, Col, Card, Form, InputGroup } from 'react-bootstrap';
import { CiSearch } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Settings() {
  return (
    <div className="bg-light" style={{ minHeight: "100vh", 
      backgroundImage: 'url("https://www.transparenttextures.com/patterns/paper.png")',
      backgroundSize: 'cover', }}>
      <NavbarComponent />
      <Container className="py-5">
        <Row className="justify-content-center align-items-center mb-4">
          <Col xs="auto">
            <p className="h4 font-weight-bold mb-0">Setup</p>
          </Col>
          <Col xs="auto">
            <InputGroup>
              <InputGroup.Text className="border-0 bg-white p-0">
                <CiSearch className="fs-4"  />
              </InputGroup.Text>
              <Form.Control type="search" placeholder="Search" className="border-start-0" />
            </InputGroup>
          </Col>
        </Row>
        <Row className="gy-4">
          <Col md={3}>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title className="h5 font-weight-bold">General</Card.Title>
                <Link to="personalsetting" className="d-block mb-2" style={{textDecoration:"none"}}>Personal Settings</Link>
                <Link to="usersetting" className="d-block mb-2"style={{textDecoration:"none"}}>Users</Link>
                <Link to="companysetting" className="d-block mb-2"style={{textDecoration:"none"}}>Company Settings</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title className="h5 font-weight-bold">Customization</Card.Title>
                <Link to="modules" className="d-block mb-2"style={{textDecoration:"none"}}>Modules and Fields</Link>
                <Link to="pipelines" className="d-block mb-2"style={{textDecoration:"none"}}>Pipelines</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title className="h5 font-weight-bold">Channels</Card.Title>
                <Link to="email" className="d-block mb-2"style={{textDecoration:"none"}}>Email</Link>
               
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="shadow-sm">
              <Card.Body>
                <Card.Title className="h5 font-weight-bold">Automation</Card.Title>
                <Link to="workflow" className="d-block mb-2"style={{textDecoration:"none"}}>Workflow Rules</Link>
                <Link to="action" className="d-block mb-2"style={{textDecoration:"none"}}>Actions</Link>
                <Link to="schedule " className="d-block mb-2"style={{textDecoration:"none"}}>Schedules</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Settings;

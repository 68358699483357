import React from 'react'

function NewHires() {
  return (
    <div>


<div style={{marginLeft:"40px",marginTop:"40px",position:"absolute"}}>
      <div style={{border:"1px solid black",width:"250px",height:"250px"}}>
        <img style={{height:"80px",width:"80px",position:"relative",left:"74px",top:"-37px"}} src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="" />
        <p style={{fontWeight:"bold",textAlign:"center"}}>S3 - Clarkson Walter</p>

        <p style={{textAlign:"center"}}>clarksonwalter@zylker.com</p>
        <p style={{textAlign:"center"}}>ADMINISTRATION</p>
        <p style={{textAlign:"center"}}>MARKETING</p>

      </div>
    </div>
    <div style={{marginLeft:"23%",marginTop:"40px",position:"absolute"}}>
      <div style={{border:"1px solid black",width:"250px",height:"250px"}}>
        <img style={{height:"80px",width:"80px",position:"relative",left:"74px",top:"-37px"}} src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="" />
        <p style={{fontWeight:"bold",textAlign:"center"}}>S3 - Clarkson Walter</p>

        <p style={{textAlign:"center"}}>clarksonwalter@zylker.com</p>
        <p style={{textAlign:"center"}}>ADMINISTRATION</p>
        <p style={{textAlign:"center"}}>MANAGEMENT</p>

      </div>
    </div>
    <div style={{marginLeft:"42%",marginTop:"40px",position:"absolute"}}>
      <div style={{border:"1px solid black",width:"250px",height:"250px"}}>
        <img style={{height:"80px",width:"80px",position:"relative",left:"74px",top:"-37px"}} src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt="" />
        <p style={{fontWeight:"bold",textAlign:"center"}}>S3 - Clarkson Walter</p>

        <p style={{textAlign:"center"}}>clarksonwalter@zylker.com</p>
        <p style={{textAlign:"center"}}>ADMINISTRATION</p>
        <p style={{textAlign:"center"}}>IT</p>

      </div>
    </div>
    </div>
  )
}

export default NewHires
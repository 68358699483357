import React from 'react'
import NavbarComponent from './Navbar'
import { CiSearch } from "react-icons/ci";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const label = { inputProps: { 'aria-label': 'Switch demo' } };

function WorkflowAutomation() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div>
        <NavbarComponent/>

        <div className='flex row'>
        <div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p>Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
      

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
        <div style={{width:"68%",border:"1px solid black",margin:"10px"}}>
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Rules" value="1" />
            <Tab label="Usage" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
            
            <div>
                <h4>Workflow Rules</h4>
                <p>Workflow rules allow you to perform certain automatic actions on specific records based on filter criteria. Workflow automations can send emails, update fields, create records and much more.</p>

                <div style={{display:"flex",justifyContent:"space-between"}}>
                <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
            <div>
                <button onClick={handleOpen}style={{backgroundColor:"blue",color:"white"}}>+ Create Rule</button>
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create New Rule
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
                <label htmlFor=""className='col-md-6'>Module - &nbsp;</label>
                <select name="" id=""className='col-md-6 p-1'>
                    <option value="">select</option>
                    <option value="">Leads</option>
                    <option value="">Contacts</option>
                    <option value="">Accounts</option>
                    <option value="">Deals</option>
                </select>
                <br />
                <br />
                <label htmlFor=""className='col-md-6'>Rule Name - &nbsp;</label>
                <input type="text"className='col-md-6' />
                <br />
                <br />
                <label htmlFor=""className='col-md-6'>Description - &nbsp;</label>
                <input type="text"className='col-md-6' />
                <br />
                <br />
                <div>
                    <button style={{backgroundColor:"blue",color:"white"}}onClick={handleClose}>Save</button> &nbsp;
                    <button onClick={handleClose}>Cancel</button>
                </div>

            </div>
          </Typography>
        </Box>
      </Modal>
            </div>
                </div>
                <div>
                <table className='table'>
            <thead>
                <tr>
                    <th>Rule Name</th>
                    <th>Module Name</th>
                    <th>Execute On</th>
                    <th>Actions</th>
                    <th>Last Modified</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Big Deal Rule</td>
                    <td>Deals</td>
                    <td>Create Or Edit</td>
                    <td>1</td>
                    <td></td>
                    <td>      <Switch {...label} defaultChecked />
                    </td>
                </tr>
            </tbody>
         </table>
                </div>
            </div>
        </TabPanel>
        <TabPanel value="2">
            
            <div>
                <h4>Usage</h4>
                <p>Top Performing Workflow Rules by Email Open Rate - Last 7days.</p>
                <table className='table'>
            <thead>
                <tr>
                    <th>Rule Name</th>
                    <th>Template Name</th>
                    <th>Open Rate (Workflow)</th>
                    <th>Open Rate (Global)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    
                </tr>
            </tbody>
         </table>
            </div>

        </TabPanel>
      </TabContext>
          
        </div>
      </div>
    </div>
  )
}

export default WorkflowAutomation
import React,{useState} from 'react'
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function Projects() {

  const [value, setValue] = React.useState(0);

 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [open, setOpen] =useState(false);
  const[page,setPage]=useState("Employee")
  
  return (
    <div>
      <div style={{display:"flex",justifyContent:"space-between"}}>
          <div >
        <div style={{margin:"20px"}}>

          <select style={{padding:"10px",paddingRight:"40px",paddingLeft:"40px"}} name="" id="">
            <option value="" style={{fontWeight:"bold"}}>Projects</option>
          </select>
        </div>
          </div>
        <div style={{margin:"20px",display:"flex",gap:"20px"}}>
         
          <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Employee" onClick={()=>setPage("Employee")} />
        <Tab label="Department" onClick={()=>setPage("Department")}/>

      </Tabs>
          <Button variant="outlined" color="neutral" style={{backgroundColor:"blue",color:"white"}} onClick={() => setOpen(true)}>
       Add Projects
      </Button>
      <Modal
    
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <Sheet
          variant="outlined"
          sx={{
            Width: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
           <div>
           Project Configuration Details
           </div>
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
           <div style={{width:"700px"}}>
             <div>
              <label className='col-4' htmlFor="">Project Name</label>
              <input className='col-6' type="text" />
             </div>
             <br/>
             <div>
              <label className='col-4' htmlFor="">Client Name</label>
              <select className='col-6 p-1' name="" id="">
                <option value="">select</option>
              </select>
             </div>
             <br/>
  
             <div>
              <label className='col-4' htmlFor="">Project Cost</label>
              <input className='col-6' type="text" />
             </div>
             <br/>
             <div>
              <label className='col-4' htmlFor="">Project Head</label>
              <select className='col-6 p-1' name="" id="">
                <option value="">Mitesh Waghmode</option>
              </select>        
             </div>
             <br/>
           <div>
             <label className='col-4' htmlFor="">Rate</label>
              <input className='col-6' type="text"placeholder='Rate' />
             </div>
             <br/>
  
             <div>
              <label className='col-4' htmlFor="">Project Manager</label>
              <input className='col-6' type="text"placeholder='Project Manager' />
             </div>
             <br/>
  
             <div>
              <label className='col-4' htmlFor="">Description</label>
              <input className='col-6 p-2' type="text" />
             </div>
            <br/>
             <div style={{display:"flex",gap:"20px"}}>
              <button onClick={()=>setOpen(false)} style={{backgroundColor:"skyblue",color:"white",border:"1px solid blue"}}>Submit</button>
              <button onClick={()=>setOpen(false)} style={{backgroundColor:"red",color:"white",border:"1px solid red"}}>Cancel</button>
             </div>
           </div>
          </Typography>
        </Sheet>
      </Modal>
        </div>
      </div>

     {
      page=="Employee"?<> <div style={{padding:"20px"}}>
        <table className='table' >
          <thead >
            <tr >
              <th style={{backgroundColor:"#e7e5e5"}}>Project Name</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Estimated Hours</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Logged Hours</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Status</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Jobs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
             
              
              <td> <Link to="projectDash">CBS-Mitesh Waghmode  </Link></td>
              <td>-</td>
              <td>-</td>
              <td>In-Progress</td>
              <td>Add Job</td>
            
            </tr>
          </tbody>

        </table>
        
        </div></>:<> <div style={{width:"80%",height:"300px",backgroundColor:"#e7e5e5",margin:"0 auto",alignItems:"center",display:"flex",justifyContent:"center"}}>
        <div style={{display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
          <img style={{width:"150px",height:"150px"}} src="https://static.zohocdn.com/zp5/people5/images/nodata/no_data_found.5d034c55f50a90857eac7e424b646d1a.svg" alt="" />
          <p style={{paddingTop:"10%",fontSize:"20px"}}> No Projects added currently. To add new Projects, click Add Project</p>
        </div>
      </div></>
     }
    </div>
  )
}

export default Projects
import React from 'react'
import NavbarComponent from './Navbar'
import { CiSearch } from "react-icons/ci";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { TfiEmail } from "react-icons/tfi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { MdOutlineAttachEmail } from "react-icons/md";
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function EmailChannel() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  
  return (
    <div>
        
        <NavbarComponent/>

        <div>
        <div className='flex row'>
        <div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p>Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
      

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
        <div style={{width:"68%",border:"1px solid black",margin:"10px"}}>
           <div>
           <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Email Configuration</p>
           </div>

           <div>
           <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Compose" value="1" />
            <Tab label="Email" value="2" />
            <Tab label="Email Sharing" value="3" />
            <Tab label="Organization Email" value="4" />
            <Tab label="Custom Email Prefernce" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <div>
                <h4>Compose Settings</h4>
                <p>Configure basic default email compose settings to personalize your composing experience.</p>
        <div>
            <label htmlFor=""className='col-md-6'>Default Font Family : </label>
            <select className='col-md-5 p-1' name="" id="">
                <option value="">verdana</option>
                <option value="">Arial</option>
                <option value="">Calibri</option>
            </select>
            <br />
            <br />
            <label htmlFor=""className='col-md-6'>Default Font Size : </label>
            <select className='col-md-4 p-1' name="" id="">
                <option value="">10</option>
                <option value="">12</option>
                <option value="">14</option>
            </select>
            <br />
            <br />
            <label htmlFor=""className='col-md-6'>Default Email Address : </label>
            <select className='col-md-5 p-1' name="" id="">
                <option value="">mitesh@gmail.com</option>
                
            </select>
            <br />
            <br />
            <label htmlFor="">Email Signature - </label>
            <span style={{color:"blue"}}>Add New Signature</span>
        </div>
            </div>
        </TabPanel>
        <TabPanel value="2">
            <div>
                <h4>Configure Email</h4>
                <p>Connect your email inbox with CRM and transform the way you do sales.</p>

                <div style={{display:"flex",gap:"20px"}}>
                <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <div style={{display:"flex",justifyContent:"center",padding:"5px"}}>

      <MdOutlineMarkEmailRead style={{fontSize:"40px"}}/>
        </div>
        <CardContent>
         
          <Typography variant="body2" color="text.secondary">
          Access your customer emails with holistic CRM information
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
      <div style={{display:"flex",justifyContent:"center",padding:"5px"}}>


      <MdOutlineAttachEmail style={{fontSize:"40px"}}/>
        </div>
        <CardContent>
         
          <Typography variant="body2" color="text.secondary">
          Send and receive mails from inside CRM records
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
      <div style={{display:"flex",justifyContent:"center",padding:"5px"}}>

      <TfiEmail style={{fontSize:"40px"}}/>
        </div>
        <CardContent>
         
          <Typography variant="body2" color="text.secondary">
          Synchronize your email inbox with Zoho CRM
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
                </div>
                <div style={{display:"flex",justifyContent:"center",padding:"10px"}}>
                    <button style={{backgroundColor:"blue",color:"white"}}>Get Started</button>
                </div>
            </div>
        </TabPanel>
        <TabPanel value="3">
            
            <div>
                <div>
                    <h4>Email Sharing Permissions</h4>
                    <p>Take a complete control of the email sharing permissions of your organization users.</p>
                </div>
                <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
            <div>
            <table className='table'>
            <thead>
                <tr>
                    <th><input type="checkbox" /> User</th>
                    <th>Configuration Type</th>
                    <th>Sharing</th>
                    <th>Domain Excluded</th>
                    <th>Emails Share with</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td> Mitesh Waghmode <br /> CEO - Administrator</td>
                    <td>Not Configured</td>
                    <td>Private</td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
         </table>
            </div>
            </div>
        </TabPanel>
        <TabPanel value="4">
            <div>
                <h4>Organization Email Addresses</h4>
                <p>Add the email addresses of your various departments from which you send and receive customer emails. You can use these addresses as the 'From' and 'Reply to' addresses. The email addresses have to be verified before you can use them.</p>

                <div>
                    <button style={{backgroundColor:"blue",color:"white"}} onClick={handleOpen}>Add Email Address</button>
                    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Email Address
                    </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <div>
            <label htmlFor=""className='col-md-5'>Display Name</label>
            <input type="text" className='col-md-6'/>
            <br />
            <br />
            <label htmlFor=""className='col-md-5'>Email Address</label>
            <input type="text"className='col-md-6' />
            <br /><br />
            <label htmlFor=""className='col-md-5'>Who can use this Email Address</label>
            <select name="" id=""className='col-md-5 p-1'>
              <option value="">Administrator</option>
              <option value="">Standard</option>
            </select>
            <br /><br />
            <div>
              <button style={{backgroundColor:"blue",color:"white"}} onClick={handleClose}>Save</button>&nbsp;
              <button onClick={handleClose}>Cancel</button>
            </div>
           </div>
          </Typography>
        </Box>
      </Modal>
                </div>
            </div>
        </TabPanel>
        <TabPanel value="5">
            <div>
                <h4>Custom Email Fields Preferences  <Switch {...label} /></h4>
                <p>Choose whether you want to sync email conversation with the email addresses stored in the custom email fields across all records in Zoho CRM.</p>

                <h5>By enabling custom email preferences, your organisations users can:</h5>
                <ul>
                    <li>View email conversations with email addresses present in custom email fields, within the email related list of the corresponding record.</li>
                    <li>Get quick suggestions from the email composer to add addresses within custom email fields when using the Send Email button for every record.</li>
                    <li>Add more data to all existing email reports, sourced from email conversations with email addresses present in custom email fields.</li>
                    <li>Use the Smart Filter to search for records based on the 'latest email status' for email conversations with email addresses present in custom email fields.</li>
                </ul>
            </div>
        </TabPanel>
      </TabContext>
           </div>

        
        </div>
      </div>
        </div>
    </div>
  )
}

export default EmailChannel
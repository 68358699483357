import React from 'react'

function Department() {
    
  return (
    <div style={{backgroundColor:"#e7e5e5"}}>
        
        <div style={{display:"flex",gap:'100px',border:"1px solid black",padding:"10px",backgroundColor:"white"}}> 
            <select style={{padding:"10px",paddingRight:"100px",fontSize:"20px"}} name="" id="">
                <option value="">My Department</option>
                <option value="">Management</option>
            </select>
            <select style={{padding:"20px",paddingRight:"100px",fontSize:"20px"}} name="" id="">
                <option value="" >Unspecified Loacation</option>
            </select>
            <p style={{fontSize:"20px"}}>5 <br /> Members</p>
        </div>
        <div style={{display:"flex",gap:"10px"}}>
          <div>

       
          <div style={{padding:"20px",border:"1px solid black",margin:"20px",borderRadius:"10px",backgroundColor:"white"}}>
            <div style={{display:"flex",gap:"230px"}}>
              <p style={{fontWeight:"bold",fontSize:"17px"}}>CEO</p>
              <p style={{fontWeight:"bold",fontSize:"17px"}} >1</p>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",gap:"20px"}}>
              <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
              <p style={{fontWeight:"bold"}}>1-Mitesh Waghmode</p>
              <p style={{color:"red"}}>Yet To Check-in</p>
            </div>
          </div>
          </div>
          <div style={{padding:"20px",border:"1px solid black",margin:"20px",borderRadius:"10px",backgroundColor:"white"}}>
            <div style={{display:"flex",gap:"100px"}}>
              <p style={{fontWeight:"bold",fontSize:"17px"}}>Administrations</p>
              <p style={{fontWeight:"bold"}}>4</p>
            </div>
            <div>
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                <p>230-103-239</p>
                </div>

              <div>
                <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                  <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                  <p>230-103-239</p>
                </div>

              <div>
              <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                  <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                  <p>230-103-239</p>
                </div>

              <div>
              <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
    </div>
  )
}

export default Department
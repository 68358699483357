import React from 'react'
import NavbarComponent from './Navbar'
import { CiSearch } from "react-icons/ci";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function ActionAutomation() {
    const [value, setValue] = React.useState('1');
 const navigate=useNavigate()

function handleNavigate(){
  navigate("/Task")
}

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div>
        <NavbarComponent/>

        
      <div className='flex row'>
      <div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p >Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
      

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
        <div style={{width:"68%",border:"1px solid black",margin:"10px"}}>
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Email Notification" value="1" />
            <Tab label="Tasks" value="2" />
            <Tab label="Field Updates" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <div>
                <h4>Email Notifications</h4>
                <p>Email notifications let users know a particular event has occurred.</p>

                <div style={{display:"flex",justifyContent:'flex-end'}}>
                    <button style={{backgroundColor:"blue",color:'white'}}onClick={handleOpen}>+ New Email Notification</button>
                    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           New Email Notification
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
                <label htmlFor=""className='col-md-6'>Name - &nbsp;</label>
                <input type="text"className='col-md-6' />
                <br /><br />
                <label htmlFor=""className='col-md-6'>Module - &nbsp;</label>
                <select name="" id=""className='col-md-6 p-1'>
                    <option value="">Leads</option>
                    <option value="">Contacts</option>
                    <option value="">Accounts</option>
                </select>
                <br /><br />
                <label htmlFor=""className='col-md-6'>To - &nbsp;</label>
                <input type="text"className='col-md-6' />
                <br />
                <input type="checkbox" />
                <label htmlFor="">Send this notification as a Single Mass Email with all recipients displayed</label>

                <br />
                <div>
                    <button style={{backgroundColor:"blue",color:"white"}}onClick={handleClose}>Save</button> &nbsp;
                    <button onClick={handleClose}>Cancel</button>
                </div>
            </div>
          </Typography>
        </Box>
      </Modal>
                </div>
                <div>
                <table className='table'>
            <thead>
                <tr>
                    <th> Name</th>
                    <th>No of Recipients</th>
                    <th>All Modules</th>
                    <th>Email Templates</th>
                  
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Big Deal Alert</td>
                    <td>1</td>
                    <td>Deals</td>
                    <td>Big Deal Alert</td>
                    
                </tr>
            </tbody>
         </table>
                </div>
            </div>
        </TabPanel>
        <TabPanel value="2">
            <div>
                <h4>Tasks</h4>
                <p>These are tasks assigned to users when Workflow Rules, Blueprints, or Approval Processes are executed</p>
                <div style={{display:'flex',justifyContent:"flex-end"}}>
                    <button onClick={handleNavigate} style={{backgroundColor:"blue",color:"white"}}>+ Create Task</button>
                </div>
                <div>
                <table className='table'>
            <thead>
                <tr>
                    <th> Subject</th>
                    <th>All Modules</th>
                    <th>Due Date</th>
                    <th>Priority</th>
                    <th>Status</th>
                    <th>Assign To</th>
                  
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Reminder task for -Tasks.Subject</td>
                    <td>tasks</td>
                    <td>	
                    Reminder minus 1 day(s)</td>
                    <td>Big Deal Alert</td>
                    <td>Highest</td>
                    <td>waiting for</td>
                    
                </tr>
                <tr>
                    <td>Reminder task for Deffered -Tasks.Subject</td>
                    <td>tasks</td>
                    <td>	
                    Reminder minus 1 day(s)</td>
                    <td>Big Deal Alert</td>
                    <td>Highest</td>
                    <td>Deffered</td>
                    
                </tr>
            </tbody>
         </table>
                </div>
            </div>
        </TabPanel>
        <TabPanel value="3"><div>
            <h4>Field Updates</h4>
            <p>Field update actions allow you to update the values of specified fields when the associated Workflow Rules, Blueprints, or Approval Processes are executed.</p>
            <div style={{display:"flex",justifyContent:"flex-end"}}>
                <button  onClick={handleOpen} style={{backgroundColor:"blue",color:"white"}}>Configure field update</button>
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          New Workflow Field Update
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <label htmlFor=""className='col-md-6'>Name :</label>
              <input type="text" className='col-md-6'/>
              <br /><br />
              <label htmlFor=""className='col-md-6'>Module :</label>
              <select name="" id=""className='col-md-6 p-1'>
                <option value="">Leads</option>
                <option value="">Contacts</option>
                <option value="">Account</option>
                <option value="">Details</option>
              </select>
              <br /><br />
              <label htmlFor=""className='col-md-6'>Update :</label>
              <select name="" id=""className='col-md-6 p-1'>
                <option value="">None</option>
                <option value="">Country</option>
                <option value="">Title</option>
                <option value="">Phone</option>
                <option value="">Fax</option>
              </select>
              <br /><br />
              <button onClick={handleClose} style={{backgroundColor:"blue",color:"white"}}>Save</button>&nbsp;
              <button onClick={handleClose}>Cancel</button>
            </div>
          </Typography>
        </Box>
      </Modal>
            </div>
            <div>
                <table className='table'>
            <thead>
                <tr>
                    <th> Name</th>
                    <th>All Modules</th>
                    <th>Field To Update</th>
                    <th>New Value</th>
                   
                  
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Lead status Update</td>
                    <td>Leads (Qualify Leads through Call)
                    </td>
                    <td>	
                    Lead Status</td>
                    <td>Contacted</td>
                   
                    
                </tr>
                <tr>
                    <td>Lead  Contacted</td>
                    <td>Leads (Qualify Leads through Call)
                    </td>
                    <td>	
                    Lead Status</td>
                    <td>Contacted</td>
                   
                    
                </tr>
           
            </tbody>
         </table>
                </div>
            </div></TabPanel>
      </TabContext>

          
        </div>
      </div>
    </div>
  )
}

export default ActionAutomation
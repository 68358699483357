import React from "react";
import NavbarComponent from "./Navbar";
import { CiSearch } from "react-icons/ci";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaPen } from "react-icons/fa";
import { GiRotaryPhone } from "react-icons/gi";
import { FaPrint } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from "react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CompanyDetailsSettings() {
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <NavbarComponent />
      <div>
        <div className="flex row">
        <div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p>Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
      

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
          <div
            style={{ width: "68%", border: "1px solid black", margin: "10px" }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Company Details" value="1" />
                  <Tab label="Business Hours" value="2" />
                  <Tab label=" Hierarchy Preference " value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ68O8fr2ERGCyg-f_AyAk1d1u4_PGr7XzLYzQoWva1cP19IfDGgJtiK_Ol6d0EXCMfmmg&usqp=CAU"
                      style={{ width: "93px", height: "93px" }}
                      alt=""
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span style={{ fontWeight: "bold" }}>
                        AIT - <FaPen  onClick={handleOpen}/>
                        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Company Details
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <label htmlFor=""className="col-md-6">Company Name : </label>
              <input type="text" className="col-md-6" />
              <br />
              <br />
              <label htmlFor="" className="col-md-6">Alias : </label>
              <input type="text"className="col-md-6" />
              <br />
              <br />
              <label htmlFor=""className="col-md-6">Employee Count : </label>
              <input type="text" className="col-md-6"/>
              <br />
              <br />
              <label htmlFor=""className="col-md-6">Phone : </label>
              <input type="text" className="col-md-6"/>
              <br />
              <br />
              <label htmlFor=""className="col-md-6">Website : </label>
              <input type="text" className="col-md-6"/>
              <br />
              <br />
              <label htmlFor=""className="col-md-6">Description : </label>
              <input type="text"className="col-md-6" />
              <br />
              <br />
              <label htmlFor=""className="col-md-6">Street : </label>
              <input type="text"className="col-md-6" />
              <br />
              <br />
              <label htmlFor=""className="col-md-6">City : </label>
              <input type="text"className="col-md-6" />
              <br />
              <br />
              <label htmlFor=""className="col-md-6">Country : </label>
              <input type="text"className="col-md-6" />
              <br />
              <br />
              <label htmlFor=""className="col-md-6">Status : </label>
              <input type="text" className="col-md-6"/>
              <br />
              <br />
             <button style={{backgroundColor:"blue",color:"white"}}onClick={handleClose}>Save</button>
             &nbsp;
             <button onClick={handleClose}>Cancel</button>
            </div>
          </Typography>
        </Box>
      </Modal>
                      </span>

                      <span>
                        <MdOutlineEmail /> mitesh@gmail.com
                      </span>
                      <span>
                        <GiRotaryPhone /> 90909565458
                      </span>
                      <span>
                        <FaPrint />
                      </span>
                      <span>
                        <FaLocationDot />
                      </span>
                      <span>
                        <FaPhoneAlt />
                      </span>
                    </div>
                  </div>

                  <br />
                  <div>
                    <h5>Access URL</h5>
                    <ul>
                      <li>URL - ""</li>
                    </ul>
                  </div>
                  <br />
                  <div>
                    <h5>Locale Information</h5>
                    <ul>
                      <li>
                        Time Zone - (GMT 5:30) India Standard Time
                        (Asia/Kolkata){" "}
                      </li>
                    </ul>
                  </div>
                  <br />
                </div>
              </TabPanel>
              <TabPanel value="2">
                <div>
                  <div style={{display:'flex',gap:"20px"}}>
                    <div>
                      <h4>Business Hours</h4>
                      <p style={{width:"700px"}}>
                        Business hours define the operational hours of your
                        organization. Set business hours to help your employees
                        ensure that the activities are carried out at the
                        operational hours of your organization.
                      </p>
                    </div>
                    <div>
                    <Button variant="contained" color="primary" onClick={handleOpen}>
        + New Business Hours
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Business Hours
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <p>Business Hours:</p>

            <div>
              <input type="radio" id="Netflix" name="brand" value="Netflix" />
              <label htmlFor="Netflix">24 Hours X 7 Days</label>
            </div>

            <div>
              <input type="radio" id="Audi" name="brand" value="Audi" />
              <label htmlFor="Audi">24 Hours X 5 Days</label>
            </div>

            <div>
              <input
                type="radio"
                id="Microsoft"
                name="brand"
                value="Microsoft"
                defaultChecked
              />
              <label htmlFor="Microsoft">Custom Hours</label>
            </div>
            <br />
            <div>
              <label htmlFor="weekStart">Week Starts On: &nbsp;</label>
              <select name="weekStart" id="weekStart">
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
              </select>
            </div>
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
         
            <button onClick={handleClose} style={{backgroundColor:"blue",color:"white",borderRadius:"5px"}}>Save</button>
            <button onClick={handleClose} style={{backgroundColor:"gray",color:"white",borderRadius:"5px"}}>Cancel</button>
          </Box>
        </Box>
      </Modal>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="3">
                
                <div>
                    <div>
                        <h4>Hierarchy Preference</h4>
                        <p>Choose your preferred hierarchy for your organization.</p>
                  </div>
                  <div>
                  <div>
              <input type="radio" id="role" name="brand" value="role" />&nbsp;
              <label htmlFor="role">Role Hierarchy </label>
            </div>

            <div>
              <input type="radio" id="report" name="brand" value="report" />&nbsp;
              <label htmlFor="report">Reporting Hierarchy </label>
            </div>
                  </div>

                </div>
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetailsSettings;

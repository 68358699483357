import React from "react";
import "./newProfile.css";
import {
  MdOutlineAccountTree,
  MdOutlineCall,
  MdOutlineAccessTime,
  MdEmail,
  MdChairAlt,
  MdNightlightRound,
} from "react-icons/md";
import useAuth from "./Context";
import { GoPlusCircle } from "react-icons/go";
import { MdOutlineMessage, MdCall, MdOutlineVideoCall } from "react-icons/md";
import { CiStar } from "react-icons/ci";
import { PiArrowsDownUp } from "react-icons/pi";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FaUmbrellaBeach } from "react-icons/fa";
import { IoAirplaneSharp } from "react-icons/io5";
import { GoTrophy } from "react-icons/go";
import { VscFileSubmodule } from "react-icons/vsc";
import { GoStopwatch } from "react-icons/go";
import { PiBuildingsFill } from "react-icons/pi";
import { FaRegClipboard } from "react-icons/fa6";
import { FaPlane } from "react-icons/fa";

function NewProfile() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [value, setValue] = React.useState(0);
  const[orgTab,setOrgTab]=useState("Profile")

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const auth = useAuth();
  useEffect(() => {
    auth?.setIsNewProfileClicked(true);
  });
  return (
    <div style={{}}>
      <div
        className="main"
        style={{ display: "flex", gap: "10px", paddingLeft: "85%" }}
      >
        <MdOutlineMessage
          style={{ backgroundColor: "white", fontSize: "30px" }}
        />
        <MdCall style={{ backgroundColor: "white", fontSize: "30px" }} />
        <MdOutlineVideoCall
          style={{ backgroundColor: "white", fontSize: "30px" }}
        />
        <CiStar style={{ backgroundColor: "white", fontSize: "30px" }} />
      </div>
      <div style={{ width: "100%", height: "25%", backgroundColor: "#eeeeee" }}>
        <div
          style={{
            margin: "40px",
            backgroundColor: "#eeeeee",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <img
              style={{
                width: "150px",
                height: "150px",
                position: "relative",
                top: "-95px",
              }}
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt=""
            />
            <h4 style={{ marginTop: "60px" }}>
              S2-Lilly Williams <small>&nbsp; Yet To Check</small> <br />
              Administrations
            </h4>
          </div>
          <div style={{ padding: "30px" }}>
            <p>
              Reporting To <br px /> Mitesh Waghmode
            </p>
          </div>
        </div>
        <hr />
        <div style={{ display: "flex", gap: "20px", paddingLeft: "25px" }}>
          
          <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Profile" onClick={()=>setOrgTab("Profile")}/>
        <Tab label="Reportees" onClick={()=>setOrgTab("Reportees")}/>
        <Tab label="Department" onClick={()=>setOrgTab("Department")}/>
        <Tab label="Related Data" onClick={()=>setOrgTab("Related Data")}/>
      </Tabs>
        </div>
      </div>

{
  orgTab=="Profile"?<> <div>

    
  <div style={{ border: "1px solid black", margin: "20px" }}>
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
      >
        <MdOutlineAccountTree
          style={{ fontSize: "50px", paddingRight: "10px" }}
        />
        <p>
          Department
          <br />
          Management
        </p>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
      >
        <MdNightlightRound
          style={{ fontSize: "50px", paddingRight: "10px" }}
        />
        <p>
          Shift
          <br />
          General
        </p>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
      >
        <MdOutlineAccessTime
          style={{ fontSize: "50px", paddingRight: "10px" }}
        />
        <p>
          Time Zone
          <br />
          (GMT+05:30)
        </p>
      </div>
    </div>
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
      >
        <MdChairAlt style={{ fontSize: "50px", paddingRight: "10px" }} />
        <p>
          Seating Location
          <br />
          FL_EXEC_1
        </p>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
      >
        <MdEmail style={{ fontSize: "50px", paddingRight: "10px" }} />
        <p>
          Email
          <br />
          abc@gmail.com
        </p>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", padding: "10px" }}
      >
        <MdOutlineCall style={{ fontSize: "50px", paddingRight: "10px" }} />
        <p>
          Work Phone Number
          <br />
          305-555-1212
        </p>
      </div>
    </div>
  </div>

  <div style={{ border: "1px solid black", margin: "20px" }}>
    <div style={{ display: "flex", gap: "50%", padding: "15px" }}>
      <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>Skills</p>
      <div style={{ marginTop: "35px" }}>
        <GoPlusCircle style={{ fontSize: "57px", paddingLeft: "20px" }} />
        <br />
        <Button variant="primary" onClick={handleShow}>
          ADD SKILLS
        </Button>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Skills</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="">Title </label>
            <br />
            <input type="text" style={{ marginBottom: "10px" }} />
            <br />
            <label htmlFor="">Description </label>
            <br />
            <input type="text" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Add Skills
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  </div>

  <div style={{ border: "1px solid black", margin: "20px" }}>
    <div style={{ display: "flex", gap: "50%", padding: "15px" }}>
      <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>About</p>
      <div style={{ marginTop: "35px" }}>
        <GoPlusCircle style={{ fontSize: "57px", paddingLeft: "20px" }} />
        <br />
        <Button variant="primary" onClick={handleShow1}>
          ADD ABOUT
        </Button>
        <Modal show={show1} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Add About</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="">Description</label>
            <br />
            <textarea name="" id=""></textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose1}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleClose1}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  </div>

  <div style={{ border: "1px solid black", margin: "20px" }}>
    <div style={{ padding: "15px" }}>
      <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>
        Work Experience
      </p>
      <div>
        <table style={{ margin: "auto auto" }}>
          <thead>
            <tr>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                Company Name &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                Job Title &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                From Date &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                To Date &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                Job Description &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                Relavant &nbsp;
                <PiArrowsDownUp />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                Infomax
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                Team Lead
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                04 Jan 2009
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                20 Jan 2011
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                -
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                Yes
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div style={{ border: "1px solid black", margin: "20px" }}>
    <div style={{ padding: "15px" }}>
      <p style={{ paddingLeft: "30px", fontWeight: "bold" }}>
        Education Details
      </p>
      <div>
        <table style={{ margin: "auto auto" }}>
          <thead>
            <tr>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                Institute Name &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                Degree/Diploma &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                Specialization &nbsp;
                <PiArrowsDownUp />
              </td>
              <td
                style={{
                  backgroundColor: "#eeeeee",
                  padding: "15px",
                  paddingRight: "25px",
                }}
              >
                {" "}
                Date Of Completion &nbsp;
                <PiArrowsDownUp />
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                Crestwood College
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                Master
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                Cybersecurity
              </td>
              <td
                style={{
                  padding: "15px",
                  paddingRight: "25px",
                  textAlign: "center",
                }}
              >
                -
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </div></>:(orgTab=="Reportees"?<>      <div>
    <div style={{display:"flex",justifyContent:"space-around",paddingTop:"20px"}}>
        <div style={{display:"flex",gap:"20px"}}>
            <img style={{width:"50px",height:"50px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
            <p style={{fontSize:"20px",marginTop:"12px",fontWeight:"bold"}}>
            S2 - Mitesh Waghmode</p>
        </div>
        <div>

       
        <div style={{display:"flex",gap:"20px"}}>
            <button>Direct - 4</button>
            <button>All - 19</button>
            
        </div>
        </div>
    </div>
    <div style={{display:"flex",flexWrap:"wrap"}}>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px"}}>
                <div>
                <img style={{width:"132px",height:"98px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                                   </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px"}}>
                <div>
                <img style={{width:"132px",height:"98px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                                   </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px"}}>
                <div>
                <img style={{width:"132px",height:"98px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                                   </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    <div style={{    width: "404px",
    height: "115px",
    display: "flex",
    alignItems: "center",
    marginTop: "50px",
  
    marginLeft: "30px",
    border: "1px solid black",
    gap: "40px",
    borderRadius:"10px"}}>
                <div>
                <img style={{width:"132px",height:"98px"}} src="https://media.istockphoto.com/id/1364917563/photo/businessman-smiling-with-arms-crossed-on-white-background.jpg?s=612x612&w=0&k=20&c=NtM9Wbs1DBiGaiowsxJY6wNCnLf0POa65rYEwnZymrM=" alt="" />
                                   </div>
                <div>
                    <p style={{fontWeight:"bold"}}>S19 - Micheal Johnason</p>
                    <span>Administration</span><br />
                    <span>Yet To Check-In</span>
                </div>
    </div>
    </div>
</div></>:(orgTab=="Department"?<><div>
<div>
<div style={{display:"flex",gap:'100px',margin:"20px",border:"1px solid black",padding:"15px",backgroundColor:"white"}}> 
            <select style={{padding:"20px",paddingRight:"100px",fontSize:"20px"}} name="" id="">
                <option value="">My Department</option>
                <option value="">Management</option>
            </select>
            <select style={{padding:"20px",paddingRight:"100px",fontSize:"20px"}} name="" id="">
                <option value="" >Unspecified Loacation</option>
            </select>
            <p style={{fontSize:"20px"}}>5 <br /> Members</p>
        </div>
        <div style={{display:"flex",gap:"10px"}}>
          <div>

       
          <div style={{padding:"20px",border:"1px solid black",margin:"20px",borderRadius:"10px",backgroundColor:"white"}}>
            <div style={{display:"flex",gap:"230px"}}>
              <p style={{fontWeight:"bold",fontSize:"17px"}}>CEO</p>
              <p style={{fontWeight:"bold",fontSize:"17px"}} >1</p>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",gap:"20px"}}>
              <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
              <p style={{fontWeight:"bold"}}>1-Mitesh Waghmode</p>
              <p style={{color:"red"}}>Yet To Check-in</p>
            </div>
          </div>
          </div>
          <div style={{padding:"20px",border:"1px solid black",margin:"20px",borderRadius:"10px",backgroundColor:"white"}}>
            <div style={{display:"flex",gap:"100px"}}>
              <p style={{fontWeight:"bold",fontSize:"17px"}}>Administrations</p>
              <p style={{fontWeight:"bold"}}>4</p>
            </div>
            <div>
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                <p>230-103-239</p>
                </div>

              <div>
                <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                  <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                  <p>230-103-239</p>
                </div>

              <div>
              <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
              <div style={{display:"flex",gap:"20px"}}>
                <div><img style={{width:"60px",height:"60px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" /></div>
                <div>
                  <p style={{fontWeight:"bold"}}>S2-Lilly Williams</p>
                  <p>230-103-239</p>
                </div>

              <div>
              <p style={{color:"red"}}>Yet To Check-in</p>
              </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
</div>
</div></>:<><div style={{display:"flex",gap:"20px",flexWrap:"wrap",width:"1000px",margin:"0 auto",paddingTop:"30px"}}>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
        
        <FaUmbrellaBeach  style={{fontSize:"25px"}}/>
            <p style={{marginTop:"10px"}}>Leave Tracker</p>
        
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
    <VscFileSubmodule  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Files</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
    <GoStopwatch  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>TIme Tracker</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
    <PiBuildingsFill  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Exit Details</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
    <FaRegClipboard  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Attendance</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
    <IoAirplaneSharp  style={{fontSize:"25px"}}/>
   <p style={{marginTop:"10px"}}>Travel Expense</p>
    </div>
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
    <GoTrophy  style={{fontSize:"25px"}}/>
    <p style={{marginTop:"10px"}}>Performance</p>
    </div>
   
    <div style={{width:"400px",height:"60px",border:"1px solid black",display:"flex",padding:"10px",alignItems:"center",gap:"25px",borderRadius:"10px"}}>
    <FaPlane  style={{fontSize:"25px"}}/>
        <p style={{marginTop:"10px"}}>Travel Request</p>
    </div>
</div></>))
}
     


{/* Reportess */}


{/* Department */}



{/* Related Data */}


    </div>
  );
}

export default NewProfile;

//https://static.zohocdn.com/zp5/people5/images/home/ms-bg0.60f9344acf91cb5cee8c8e02de6895ef.jpg

import React from 'react'

function TeamList() {
  return (
    <div>
      <div style={{display:"flex",alignItems:"center",gap:"50%"}}>
            <div style={{display:"flex",alignItems:"center",textAlign:"center",padding:"10px"}}>
            <select style={{padding:'5px'}} name="" id="">
            <option value="">Employee View</option>
                <option value="">Diable Employee View</option>
              </select>
              <p style={{padding:"15px",color:"blue",marginTop:"10px"}}>Edit</p>
            </div>
            <div style={{display:"flex",alignItems:"center",textAlign:"center",padding:"10px"}}>
            <p style={{padding:"15px",color:"blue",marginTop:"10px"}}>View All Data</p>
            <select style={{padding:'5px'}} name="" id="">
                <option value="">Reportees Data</option>
                <option value="">Direct Reportees Data</option>
              </select>
            </div>
      </div>

      <div style={{padding:"20px"}}>
      <table className='table'>
          <thead >
            <tr >
              <th style={{backgroundColor:"#e7e5e5"}}>Employee Id</th>
              <th style={{backgroundColor:"#e7e5e5"}}>First Name</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Last Name</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Email Address</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Photo</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Department</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Designation</th>
              <th style={{backgroundColor:"#e7e5e5"}}>Reporting Manager</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>S20</td>
              <td>Christhopar</td>
              <td>Brown</td>
              <td>	christopherbrown@zylker.com</td>
              <td>
                <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
              </td>
              <td>Management</td>
              <td>Administration</td>
              <td>Mitesh Waghmode</td>
              
            </tr>
            <tr>
              <td>S20</td>
              <td>Christhopar</td>
              <td>Brown</td>
              <td>	christopherbrown@zylker.com</td>
              <td>
                <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
              </td>
              <td>Management</td>
              <td>Administration</td>
              <td>Mitesh Waghmode</td>
              
            </tr>
            <tr>
              <td>S20</td>
              <td>Christhopar</td>
              <td>Brown</td>
              <td>	christopherbrown@zylker.com</td>
              <td>
                <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
              </td>
              <td>Management</td>
              <td>Administration</td>
              <td>Mitesh Waghmode</td>
              
            </tr>
            <tr>
              <td>S20</td>
              <td>Christhopar</td>
              <td>Brown</td>
              <td>	christopherbrown@zylker.com</td>
              <td>
                <img style={{width:"30px",height:"30px"}} src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" alt="" />
              </td>
              <td>Management</td>
              <td>Administration</td>
              <td>Mitesh Waghmode</td>
              
            </tr>
          </tbody>

        </table>
      </div>
    </div>
  )
}

export default TeamList
import React from 'react'
import NavbarComponent from './Navbar'
import { CiSearch } from "react-icons/ci";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaCircle } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



function UserSetting() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <div>
        <NavbarComponent/>
        <div>
        <div className='flex row'>
        <div style={{width:"22%",border:"1px solid black",margin:"10px"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Setup</p>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
        </div>
        <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}
        >
         General
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/personalsetting" style={{textDecoration:"none"}}> <p>Personal Settings</p></Link>
       <Link to="/setting/usersetting" style={{textDecoration:"none"}}> <p>Users</p></Link>
       <Link to="/setting/companysetting" style={{textDecoration:"none"}}> <p>Company Settings</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
         Channels
        </AccordionSummary>
        <AccordionDetails>
       <Link to="/setting/email" style={{textDecoration:"none"}}><p>Email</p></Link> 
      
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{fontWeight:"bold"}}

        >
         Automation
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/workflow" style={{textDecoration:"none"}}><p>Workflow Rules</p></Link>
        <Link to="/setting/action" style={{textDecoration:"none"}}><p>Actions</p></Link>
        <Link to="/setting/schedule" style={{textDecoration:"none"}}><p>Schedules</p></Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          style={{fontWeight:"bold"}}

        >
          Customization
        </AccordionSummary>
        <AccordionDetails>
        <Link to="/setting/modules" style={{textDecoration:"none"}}><p>Modules and fields</p></Link>
        <Link to="/setting/pipelines" style={{textDecoration:"none"}}><p>Pipelines</p></Link>
        </AccordionDetails>
      </Accordion>
     
    </div>
        </div>
        <div style={{width:"68%",border:"1px solid black",margin:"10px"}}>
        <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="User" value="1" />
            <Tab label="Group" value="2" />
            <Tab label="Activate User" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><div className='flex row'>
        <div style={{width:"30%",border:"1px solid black",margin:"10px"}}>
     <div>
       <div style={{paddingTop:"5px"}}>
        <select name="" id="">
            <option value="">Active Users</option>
        </select>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      
        <button  onClick={handleOpen} style={{backgroundColor:"blue",color:"white"}}>+ New Users</button>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New User
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div>
              <label htmlFor=""className='col-md-6'>First Name - &nbsp;</label>
              <input type="text" name="" id="" className='col-md-6'/>
              <br /><br />
              <label htmlFor=""className='col-md-6'>Last Name - &nbsp;</label>
              <input type="text" name="" id=""className='col-md-6' />

              <br /><br />
              <label htmlFor=""className='col-md-6'>Email - &nbsp;</label>
              <input type="text" name="" id="" className='col-md-6'/>
              <br /><br />
              <label htmlFor=""className='col-md-6'>Role - &nbsp;</label>
              <input type="text" name="" id=""className='col-md-6' />
              <br /><br />
              <label htmlFor=""className='col-md-6'>Profile - &nbsp;</label>
              <select name="" id=""className='col-md-6 p-1'>
                <option value="">Select</option>
                <option value="">Administrator</option>
                <option value="">Standard</option>
              </select>
            <br />
            <br />
              <div>
                <button onClick={handleClose} style={{backgroundColor:"blue",color:"white"}}>Save</button> &nbsp;
                <button onClick={handleClose}>Cancel</button>
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
       </div>
       <br />
       <div>
       <div style={{display:"flex",gap:"20px",alignItems:"center",backgroundColor:"#d3cbcb"}}>
              <img src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" style={{width:"70px",height:'70px'}} alt="" />
              <div style={{display:"flex",flexDirection:"column"}}>
                <span style={{fontWeight:"bold"}}>Mitesh Waghmode </span>
                <span>CEO , <span style={{backgroundColor:"orange",padding:"3px"}}>Superadmin</span></span>
                <span> mitesh@gmail.com</span>

              </div>
            </div>
       </div>

     </div>

        </div>
        <div style={{width:"60%",border:"1px solid black",margin:"10px"}}>
           <div>
           <p style={{fontSize:"20px",fontWeight:"bold",padding:"10px"}}>Personal Settings</p>
           </div>

           <div>
            <div style={{display:"flex",gap:"20px",alignItems:"center"}}>
              <img src="https://cdn.vectorstock.com/i/500p/53/42/user-member-avatar-face-profile-icon-vector-22965342.jpg" style={{width:"93px",height:'93px'}} alt="" />
              <div style={{display:"flex",flexDirection:"column"}}>
                <span style={{fontWeight:"bold"}}>Mitesh Waghmode - <span style={{color:"red"}}>Administrator</span></span>
                <span>CEO at ABC</span>
                <span><MdOutlineEmail /> mitesh@gmail.com</span>

              </div>
            </div>
            <div>
            <Accordion style={{width:"350px"}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         Show Details
        </AccordionSummary>
        <AccordionDetails>
         <h5>User Information</h5>
         <ul>
          <li>First Name - Mitesh Waghmode</li>
          <li>Email - mitesh@gmail.com</li>
          <li>Role - CEO</li>
          <li>Profile - Administrator</li>
          <li>Date Of Birth - </li>
          <li>Phone - </li>
          <li>Mobile - </li>
         </ul>

         <h5>Address Information</h5>
         <ul>
          <li>Street</li>
          <li>City</li>
          <li>State</li>
          <li>Country</li>
         </ul>
        </AccordionDetails>
      </Accordion>

     
            </div>
            <br />
            <div>
     <h5>Locale Information</h5>
     <ul>
      <li>Language - English</li>
      <li>Country Locale - United States</li>
      <li>Date Format - DD/MM/YYYY</li>
      <li>Time Format - 12 Hours</li>
      <li>Number Format - 123,456.798</li>
     </ul>
     </div>
     <br />
     <div>
      <h5>Groups</h5>
      <ul>
        <li>Member in - </li>
      </ul>
     </div>
     <br />
     <div>
      <h5>Themes</h5>
     <div style={{display:"flex",gap:"10px",padding:'5px'}}>
     <FaCircle style={{fontSize:"30px",color:"red"}}/>
      <FaCircle style={{fontSize:"30px",color:"blue"}}/>
      <FaCircle style={{fontSize:"30px",color:"black"}}/>
      <FaCircle style={{fontSize:"30px",color:"yellow"}}/>
      <FaCircle style={{fontSize:"30px",color:"green"}}/>
      <FaCircle style={{fontSize:"30px",color:"orange"}}/>
      <FaCircle style={{fontSize:"30px",color:"skyblue"}}/>
      <FaCircle style={{fontSize:"30px",color:"gray"}}/>
      <FaCircle style={{fontSize:"30px",color:"violet"}}/>
      <FaCircle style={{fontSize:"30px",color:"pink"}}/>
      <FaCircle style={{fontSize:"30px",color:"wheat "}}/>
      <FaCircle style={{fontSize:"30px",color:"brown"}}/>
     </div>
     </div>
           </div>

        </div>
      </div></TabPanel>
        <TabPanel value="2">

            <div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>
                        <p style={{fontWeight:"bold",fontSize:"20px"}}>Group Name</p>
                        <p>Group Description</p>
                    </div>
                    <div>
                        <button onClick={handleOpen} style={{backgroundColor:'blue',color:"white"}}>Add Members +</button>
                        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add Members to Group
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           <div style={{display:"flex",gap:"30px",alignItems:"center"}}>
           <div>
              <select name="" id="" className='p-1'>
                <option value="">Users</option>
                <option value="">Role</option>
                <option value="">Group</option>
              </select>
           </div>
           <div>
            <CiSearch style={{border:"2px solid black",fontSize:"26.9px",marginBottom:"3.1px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>

           </div>
           <div>
           <table className='table'>
            <thead>
                <tr>
                    <th>User</th>
                    <th>Email</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>No User available to add</td>
                </tr>
            </tbody>
         </table>
           </div>
         <div>
          <button onClick={handleClose} style={{backgroundColor:"blue",color:"white"}}>Save</button> &nbsp;
          <button onClick={handleClose}>Cancel</button>
         </div>
          </Typography>
        </Box>
      </Modal>
                    </div>
                </div>
                <div>
                <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Users(1)
          </button>
          <button
            className="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Roles(0)
          </button>
          <button
            className="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            Groups(0)
          </button>
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
         <br />
         <div style={{display:"flex",gap:"20px"}}>
            <select name="" id="" style={{padding:"3px",borderRadius:"5px"}}>
                <option value="">Active Users(1)</option>
            </select>
            <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
         </div>
         <br />
         <table className='table'>
            <thead>
                <tr>
                    <th>User</th>
                    <th>Email</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><input type="checkbox" /> Mitesh Waghmode</td>
                    <td>mitesh@gmail.com</td>
                    <td>Active</td>
                </tr>
            </tbody>
         </table>
        </div>
        <div
          className="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
            <br />
        <div>
        <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
            <br />
            <table className='table'>
            <thead>
                <tr>
                    <th>Roles</th>
                    <th>Subordinates</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <div>
                        <p>No Roles Added</p>
                    </div>
                                    </tr>
            </tbody>
         </table>
        </div>
        </div>
        <div
          className="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
                  <br />
        <div>
        <div>
            <CiSearch style={{border:"2px solid black",fontSize:"27px",marginBottom:"5px",borderRight:"0px ",borderRadius:"5px 0px 0px 5px",backgroundColor:"white"}}/>
            <input type="search" style={{outline:"none",borderLeft:"0px solid black",padding:"0px",borderRadius:"0px 5px 5px 0px",}}placeholder='Search'/>
            </div>
            <br />
            <table className='table'>
            <thead>
                <tr>
                    <th>Groups</th>
                    <th>Members</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <div>
                        <p>No Groups Added</p>
                    </div>
                                    </tr>
            </tbody>
         </table>
        </div>
        </div>
      </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel value="3">
            
            <div>
                <div>
                    <h4>Activate User</h4>
                    <p>This page allows you to activate and deactivate users. Note: Your organization cannot have more active users than user licenses.</p>
                
                </div>
                <br />
            <table className='table'>
            <thead>
                <tr>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Role</th>
                    <th>Profile</th>
                    <th>User Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                   <td>Mitesh Waghmode</td>
                   <td>mitesh@gmail.com</td>
                   <td>CEO</td>
                   <td>Administrator</td>
                   <td> <Switch {...label} defaultChecked /></td>
              </tr>
            </tbody>
         </table>
            </div>
        </TabPanel>
      </TabContext>

        </div>
      </div>
        </div>
    </div>
  )
}

export default UserSetting